import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./style.css";
import WorldwideIcon from "../asset/svgicons/of wold location.svg";
import TransparentIcon from "../asset/svgicons/of transparent.svg";
import WarehouseIcon from "../asset/svgicons/of warehouse.svg";
import customer from "../asset/svgicons/of 24 support.svg";
import realtimetracking from "../asset/svgicons/of-tracking.svg";
import secrityforcargo from "../asset/svgicons/of security.svg";
import florkLifter from "../asset/image/forklift.png";

function flocklifter() {
  return (
    <Container className="florkLifter_Container">
      <Row className="florkLIftDesk " >
        <Col sm={12} className="mb-5">
          <div className="ourFeature-Text text-center ">
            <div className="ourFeature ">OUR FEATURE</div>
            <span className="rightChoice">We are right choice for you</span>
          </div>
        </Col>

        <Col md={4} xs={12} className="rightChoiceColumnA p-4">
          <div className="worldWideIcon">
            <img src={WorldwideIcon}></img>
            <div>
              <span className="textHead">WORLDWIDE LOCATION</span>
              <div className="SubText">
                We deliver our cargos to the entire world. This is the first
                choice for you. It doesn't matter where the country is we are
                ready to ship there.
              </div>
            </div>
          </div>
          <div className="worldWideIcon">
            <img src={TransparentIcon}></img>
            <div>
              <span className="textHead">TRANSPARENT PRICING</span>
              <div className="SubText">
                We provide the cost of shipping goods without any hidden charges
                .
              </div>
            </div>
          </div>
          <div className="worldWideIcon">
            <img src={WarehouseIcon}></img>
            <div>
              <span className="textHead">WAREHOUSE STORAGE</span>
              <div className="SubText">
                Our incorporated supply chain solutions can assit you wit
                diminishing logistics cost and optimize stock level.
              </div>
            </div>
          </div>
        </Col>
        <Col md={4} xs={12} className="rightChoiceColumnB">
          <div className="florkLifter ">
            <img className="florkLifterImage img-fluid" src={florkLifter}></img>
          </div>
        </Col>
        <Col md={4} xs={12} className="rightChoiceColumnC p-4">
          <div className="worldWideIcon">
            <img src={customer}></img>
            <div>
              <span className="textHead">24/7 CUSTOMER SUPPORT</span>
              <div className="SubText">
                Our customer care is around the clock with customer care
                professionals to help you anytime, 24*7 to address your queries.
              </div>
            </div>
          </div>
          <div className="worldWideIcon">
            <img src={realtimetracking}></img>
            <div>
              <span className="textHead">REAL-TIME TRACKING</span>
              <div className="SubText">
                Know about the location and approximate arrival time from our
                live tracking system.
              </div>
            </div>
          </div>
          <div className="worldWideIcon">
            <img src={secrityforcargo}></img>
            <div>
              <span className="textHead">SECURITY FOR CARGO</span>
              <div className="SubText">
                Our cargo insurance will protect your package until reaches the
                destination.
              </div>
            </div>
          </div>
        </Col>
      </Row>

      <Row className="florklistTablet">
        <Col sm={6}>
          <div className="florkLiftTextTablet">
            <div className="ourFeatures text-center">OUR FEATURES</div>
            <div className="rightChoiceTablet">We are the</div>
            <div className="rightChoiceTabletB">right choice</div>
            <div className="rightChoiceTabletC">for you</div>
          </div>
        </Col>
        <Col sm={6}>
          <div className="florkLifterImage"></div>
        </Col>
        <Col sm={6}>
          <div className="worldWideIcon">
            <img src={WorldwideIcon}></img>
            <div>
              <span className="textHead">24/7 CUSTOMER SUPPORT</span>
              <div className="SubText">
                You can call our toll free/message us anytime. We will be glad
                to help you or answer your questions as soon as possible. Our
                customer care is around the clock with customer care
                professionals to help you anytime, 24*7.
              </div>
            </div>
          </div>
          <div className="worldWideIcon">
            <img src={TransparentIcon}></img>
            <div>
              <span className="textHead">TRANSPARENT PRICING</span>
              <div className="SubText">
                We deliver our cargos to the entire world. This is the first
                choice for you. It doesn't matter where the country is we are
                ready to ship there.
              </div>
            </div>
          </div>
          <div className="worldWideIcon">
            <img src={WarehouseIcon}></img>
            <div>
              <span className="textHead">WAREHOUSE STORAGE</span>
              <div className="SubText">
                We deliver our cargos to the entire world. This is the first
                choice for you. It doesn't matter where the country is we are
                ready to ship there.
              </div>
            </div>
          </div>
        </Col>
        <Col sm={6}>
          <div className="worldWideIcon">
            <img src={WorldwideIcon}></img>
            <div>
              <span className="textHead">WORLDWIDE LOCATION</span>
              <div className="SubText">
                We deliver our cargos to the entire world. This is the first
                choice for you. It doesn't matter where the country is we are
                ready to ship there.
              </div>
            </div>
          </div>
          <div className="worldWideIcon">
            <img src={TransparentIcon}></img>
            <div>
              <span className="textHead">TRANSPARENT PRICING</span>
              <div className="SubText">
                We deliver our cargos to the entire world. This is the first
                choice for you. It doesn't matter where the country is we are
                ready to ship there.
              </div>
            </div>
          </div>
          <div className="worldWideIcon">
            <img src={WarehouseIcon}></img>
            <div>
              <span className="textHead">WAREHOUSE STORAGE</span>
              <div className="SubText">
                We deliver our cargos to the entire world. This is the first
                choice for you. It doesn't matter where the country is we are
                ready to ship there.
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default flocklifter;
