import React from "react";
import { Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./style.css";
import Card from "react-bootstrap/Card";
import Courierimage from "../asset/image/img courier.jpg";
import Airportimage from "../asset/image/img airport.jpg";
import doorcargoimage from "../asset/image/img door to dr.jpg";
import packingimage from "../asset/image/img packing.jpg";
import Header from "../header";
import Shippingdestination from "../shippingdestination";
import Partners from "../cargoPartners";
import Footer from "../footer";
import { IoChevronForward } from "react-icons/io5";

import { Link, useLocation } from "react-router-dom";

function Service() {
  const location = useLocation();

  return (
    <div>
      <Container fluid className="p-5 mt-3">
        <div className="service-provide text-center mb-5">
          <span className="service-provide-texthead">Services we provide</span>
        </div>
        <Row>
          <Col md={6} sm={6} lg={3}>
            <div className="card-box p-2" style={{ width: "100%" }}>
              <Card.Img className="card-image" src={Courierimage} />
              <div className="card-body">
                <Card.Title className="card-head-text">
                  COURIER SERVICES
                </Card.Title>
                <Card.Text className="card-sub-text">
                  When time is of the essence and you require the utmost in
                  speed and reliability for your cargo, air freight is the
                  premier shipping solution. Sahari Cargo, we offer unparalleled
                  air freight services.
                </Card.Text>
                <Link to="/courierService">
                  <button className="card-button">
                    View More
                    <IoChevronForward />
                  </button>
                </Link>

                <div className="border-line1"></div>
              </div>
            </div>
          </Col>

          <Col md={6} sm={6} lg={3}>
            <div className="card-box p-2" style={{ width: "100%" }}>
              <Card.Img className="card-image" src={Airportimage} />
              <div className="card-body">
                <Card.Title className="card-head-text">
                  AIRPORT TO AIRPORT
                </Card.Title>
                <Card.Text className="card-sub-text">
                  We are ready to handle any kind of air cargo with 24 hours a
                  day.Whether the shipment we are equipped to provide on airport
                  servicesto ensure that the shipment is safe to reach
                </Card.Text>
                <Link to="/airport">
                  <button className="card-button">
                    View More
                    <IoChevronForward />
                  </button>
                </Link>

                <div>
                  <br></br>
                  <div className="border-line2"></div>
                </div>
              </div>
            </div>
          </Col>
          <Col md={6} sm={6} lg={3}>
            <div className="card-box p-2" style={{ width: "100%" }}>
              <Card.Img className="card-image" src={doorcargoimage} />
              <div className="card-body">
                <Card.Title className="card-head-text">DOOR TO DOOR</Card.Title>
                <Card.Text className="card-sub-text">
                  Our door to door has a safe, reliable, and more affordable way
                  to move. It's easy and convenient. we have stress-free and
                  hassle-free renting, loading and unloading features.
                </Card.Text>
                <Link to="./doorToDoor">
                  <button className="card-button">
                    View More
                    <IoChevronForward />
                  </button>
                </Link>
              </div>
            </div>
          </Col>

          <Col md={6} sm={6} lg={3}>
            <div className="card-box p-2" style={{ width: "100%" }}>
              <Card.Img className="card-image" src={packingimage} />
              <div className="card-body">
                <Card.Title className="card-head-text">
                  PACKING & SHIPPING
                </Card.Title>
                <Card.Text className="card-sub-text">
                  Our Packers & Mover offers transportation services, loading &
                  unloading services and relocation. We are specialized in goods
                  transportation with facilities for warehousing and storage.
                </Card.Text>
                <Link to="./packingAndShipping">
                  <button className="card-button">
                    View More
                    <IoChevronForward />
                  </button>
                </Link>
              </div>
            </div>
          </Col>
        </Row>
        <Header />
      </Container>
      {location.pathname === "/Services" ? <Shippingdestination /> : null}
      {location.pathname === "/Services" ? <Partners /> : null}
      {location.pathname === "/Services" ? <Footer /> : null}
    </div>
  );
}

export default Service;
