import { Col, Container, Row } from "react-bootstrap";
import "./style.css";
import Header from "../header";
import Partners from "../cargoPartners";
import Shippingdestination from "../shippingdestination";
import Footer from "../footer";
import { Link, useLocation } from "react-router-dom";
import Calliconsvg from "../asset/svgicons/callIcon.svg";
import LocationIcon from "../asset/svgicons/locationImg.svg";
import { Button } from "antd";
import { useState } from "react";

const branchData = [
  {
    name: "SHARAFIYA-JEDDAH",
    phone1: "050 871 7038",
    phone2: "050 521 6943",
    address:
      "Near SNB-Saudi National Bank- madeena road Sharafiyyah-Jeddah-KSA",
  },
  {
    name: "YANBU AL BAHAR",
    phone1: "055 954 3230",
    phone2: "053 019 2322",
    address:
      "Zad Albalad Mall-Near Crispy Rolls (Khair Biladi) Near Royal Plaza",
  },
  {
    name: "RABIGH-TUWAL",
    phone1: "059 596 6697",
    phone2: "057 334 5473",
    address:
      "Near Al Hind Travels Opp.Mubarak Hotel, Center Of City, Inside Souq",
  },
  {
    name: "AL MADINAH-ALDERA DIST",
    phone1: "0536 122 922",
    phone2: "050 521 6943",
    address: "Near Platinum Hotel AYOUN ROAD",
  },
  {
    name: "BAISH,JIZAN-MAIN ROAD",
    phone1: "055 879 1223",
    phone2: "053 454 1632",
    address: "Near Tahweel Al Rajhi",
  },
  {
    name: "SHUHADA-MAKKAH",
    phone1: "059 596 6673",
    phone2: "059 389 7382",
    address: "Near Saudi Arabian Airlines",
  },
];
const keralaBranch = [
  {
    name: "COCHIN",
    phone1: "+91-906 199 8090",
    phone2: "",
    address: "Cheriya Vappalassery-nedumbassery post- Eranakulam",
  },
  {
    name: "Malapuram",
    phone1: "+91-894 321 4429",
    phone2: "",
    address: "Valamangalam-Pulpatta po- Malappuram",
  },
];
function Branches(props) {
  const location = useLocation();
  const [branchList, setBranchList] = useState(true); // Default to showing KSA data
  const [branchList2, setBranchList2] = useState(false);

  const handleKSAButtonClick = () => {
    setBranchList(true);
    setBranchList2(false);
  };

  const handleKeralaButtonClick = () => {
    setBranchList(false);
    setBranchList2(true);
  };

  const HandleClick = (item) => {
    const telUri = `tel:${item}`;
    window.location.href = telUri;
  };
  return (
    <Container fluid className="g-0">
      <div className="container my-3">
        <div className="branchHeadTxt">Our Branches & Contacts</div>
        <Row style={{ marginTop: 20 }}>
          <Col md={{ span: 4, offset: 4 }}>
            <Row style={{ height: 45, background: "#f1f1e9", borderRadius: 3 }}>
              <Col
                className={
                  branchList
                    ? "branch-listBttn active"
                    : "branch-listBttnChChnge1"
                }
                onClick={handleKSAButtonClick}
              >
                KSA
              </Col>
              <Col
                className={
                  branchList2
                    ? "branch-listBttn2 active"
                    : "branch-listBttnChChnge2"
                }
                onClick={handleKeralaButtonClick}
              >
                INDIA
              </Col>
            </Row>
          </Col>
        </Row>
        <div className="row ">
          {branchList &&
            branchData.map((item, index) => {
              return (
                <>
                  <div className="col-lg-4 col-sm-6 col-12 mt-3" key={index}>
                    <div
                      className="border rounded p-3 branchCardSize h-100"
                      style={{ cursor: "pointer" }}
                      onClick={() => HandleClick(item?.phone1)}
                    >
                      <h3 className="branchnName1">{item.name}</h3>
                      <p className="branchSubTxt py-0 my-0 mt-3">
                        <span>
                          <img
                            src={Calliconsvg}
                            className="branchIconSize"
                          ></img>
                        </span>
                        {item.phone1}{" "}
                        <span className="ps-4">
                          <img
                            src={Calliconsvg}
                            className="branchIconSize"
                          ></img>
                        </span>{" "}
                        {item.phone2}
                      </p>
                      <p className="mt-2 my-0">
                        <span>
                          <img
                            src={LocationIcon}
                            className="branchIconSize"
                          ></img>
                        </span>{" "}
                        {item.address}
                      </p>
                    </div>
                  </div>
                </>
              );
            })}
          {branchList2 &&
            keralaBranch.map((item, index) => {
              return (
                <div className="col-lg-4 col-sm-6 col-12 mt-3" key={index}>
                  <div className="border rounded p-3 branchCardSize h-100">
                    <h3 className="branchnName1">{item.name}</h3>
                    <p className="branchSubTxt py-0 my-0 mt-3">
                      <span>
                        <img src={Calliconsvg} className="branchIconSize"></img>
                      </span>
                      {item.phone1}{" "}
                      {/* <span className="ps-4">
                        <img src={Calliconsvg} className="branchIconSize"></img>
                      </span>{" "}
                      {item.phone2} */}
                      {item.phone2 ? (
                        <>
                          <span className="ps-4">
                            <img
                              src={Calliconsvg}
                              className="branchIconSize"
                            ></img>
                          </span>
                          {item.phone2}
                        </>
                      ) : (
                        ""
                      )}
                    </p>
                    <p className="mt-2 my-0">
                      <span>
                        <img
                          src={LocationIcon}
                          className="branchIconSize"
                        ></img>
                      </span>{" "}
                      {item.address}
                    </p>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
      <Header />

      {location.pathname === "/Contact" ? <Shippingdestination /> : null}
      {location.pathname === "/Contact" ? <Partners /> : null}
      {location.pathname === "/Contact" ? <Footer /> : null}
    </Container>
  );
}

export default Branches;
