import React, { useEffect, useRef, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./style.css";
import { Link, useLocation } from "react-router-dom";
import Shippingdestination from "../shippingdestination";
import Header from "../header";
import Partners from "../cargoPartners";
import Footer from "../footer";

function Freequotes() {
  const user_name = useRef();
  const user_email = useRef();
  const cargo_type = useRef();
  const weight = useRef();
  const message = useRef();

  const [formInputs, setFormInput] = useState({
    name: "",
    email: "",
    cargoType: "",
    weight: "",
    message: "",
    action:""
  });
  const location = useLocation();

  const validateEmail = (email) => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    if (!emailRegex.test(email)) {
      return false;
    } else {
      return true;
    }
  };

  const requestCallbackHandler = (data,event) => {
    event.preventDefault();
    if (
      user_name.current.value === "" ||
      user_email.current.value === "" ||
      !validateEmail(user_email.current.value) ||
      cargo_type.current.value === "" ||
      weight.current.value === ""
    ) {
      window.alert("pleaser enter valid inputs");
    } else {
      setFormInput({
        name: user_name.current.value,
        email: user_email.current.value,
        cargoType: cargo_type.current.value,
        weight: weight.current.value,
        message: message.current.value,
        action:data
      });
      user_name.current.value = "";
      user_email.current.value = "";
      weight.current.value = "";
      cargo_type.current.value = "";
      message.current.value = "";
    }
  };

  useEffect(() => {
    console.log(formInputs);
  }, [formInputs]);

  return (
    <Container fluid className="mt-4 blueShirtBox freeQuotes-Section-Bg py-5">
      <div className="container px-md-5">
        <form className="px-lg-5">
          <h4 className="text-center requestA">REQUEST A</h4>
          <h2 className="text-center  freeQuotes ">Free Quote</h2>
          <div className=" row gy-3">
            <div className="col-md-6">
              <input
                type="text"
                className="form-control"
                placeholder="Your Name"
                aria-label="FullName"
                ref={user_name}
              />
            </div>
            <div className="col-md-6">
              <input
                type="email"
                className="form-control"
                placeholder="E-mail"
                aria-label="EmailID"
                ref={user_email}
              />
            </div>
            <div className="col-md-6">
              <input
                type="text"
                className="form-control"
                placeholder="Cargo type"
                aria-label="CargoType"
                ref={cargo_type}
              />
            </div>
            <div className="col-md-6">
              <input
                type="number"
                className="form-control"
                placeholder="Weight(Kg)"
                aria-label="Weight"
                ref={weight}
              />
            </div>
          </div>
          <textarea
            className="form-control mt-3"
            rows="4"
            placeholder="Message"
            ref={message}
          ></textarea>
          <div className="row mt-4">
            <div className="col-md-6 mt-md-0 mt-3">
              <button
                className="requestAcallBtn w-100"
                onClick={requestCallbackHandler.bind(null,'callBack')}
              >
                Request a Call back
              </button>
            </div>
            <div className="col-md-6 mt-md-0 mt-3">
              <button className="getQuoteButton w-100"
              onClick={requestCallbackHandler.bind(null,'getQuote')}>Get my quote</button>
            </div>
          </div>
        </form>
      </div>

      <Header />
      {location.pathname === "/requestQuote" ? <Shippingdestination /> : null}
      {location.pathname === "/requestQuote" ? <Partners /> : null}
      {location.pathname === "/requestQuote" ? <Footer /> : null}
    </Container>
  );
}

export default Freequotes;
