import "./style1.css";
import React, { useEffect } from "react";
import { Container, Row } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import saharilogo from "./asset/svgicons/sahari logo.svg";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import mailimage from "./asset/svgicons/sm mail.svg";
import { BsJustifyLeft, BsTelephoneFill } from "react-icons/bs";
import twittericon from "./asset/svgicons/SM twitter.svg";
import facebookicon from "./asset/svgicons/SM facebook.svg";
import instagramicon from "./asset/svgicons/SM instagram.svg";
import Button from "react-bootstrap/Button";
import { useState } from "react";
import { GoSearch } from 'react-icons/go';
import TrackerModal from './TrackModal/TrackerModal';
import Shippingmodal from "./shippingModal";
import { useLocation } from "react-router";
import Shippingdestination from "./shippingdestination";
import Partners from "./cargoPartners";
import Footer from "./footer";
import Header from "./header";



function Section1(props) {
  const [trackModal, setTrackModal] = useState(false);
  const [shipmentModal, setShipmentModal] = useState(false);
  const [message, setMessage] = useState('')
  const location = useLocation();

  // console.log(message);



  return (
    <div>
      <Container fluid className="first-container p-0">
        <div className="city-image-section-desk">
          <div className="city-image">
            <div className="logo-Dot  ">
              <div className="red-Dot-Text-Desktop mb-3">
                <span className="dot-Text me-4">FAST</span>
                <span className="blue-dot"></span>
                <span className="dot-Text ms-4 me-4 ">SECURE</span>
                <span className="blue-dot"></span>
                <span className="dot-Text ms-4">WORLDWIDE</span>
                <div className="social-icon-nav-tab mt-4">
                  <a href="#">
                    <img className="twitt-icon me-5" src={twittericon}></img>
                  </a>
                  <a href="#">
                    <img className="fb-icon me-5" src={facebookicon}></img>
                  </a>
                  <a href="#">
                    <img className="insta-icon " src={instagramicon}></img>
                  </a>
                </div>
              </div>

              <div className="red-Dot-Text-mobile mb-3">
                <span className="dot-Text-mob me-3 ">FAST</span>
                <span className="dot-text-mob-line">|</span>
                <span className="dot-Text-mob ms-3 me-3">SECURE</span>
                <span className="dot-text-mob-line">|</span>
                <span className="dot-Text-mob ms-3">WORLDWIDE</span>
              </div>
              <div className="sahariLogo">
                <img className="logo-Sahari" src={saharilogo}></img>
              </div>
              <InputGroup className="inputGroupMob mt-4">

                <Form.Control
                  // id="inputValue"
                  className="inputBarMob"
                  placeholder="Enter the Track ID"
                  onChange={(e) => setMessage(e.target.value)}
                />
                <Button
                  id="basic-addon2"
                  className="trackButtonMob"
                  onClick={() => setTrackModal(!trackModal)}
                >
                  <GoSearch className="searchBar"></GoSearch>
                </Button>
              </InputGroup>

              {/* tabletTrackButton */}

              <div className="inputMainSetTab">
                <InputGroup className="inputGroup mt-4">
                  <Form.Control
                    // id="inputValue"
                    className="inputBarTab"
                    placeholder="Enter the Track ID to track Shipment"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                  />
                  <Button
                    id="basic-addon2"
                    className="trackButtontab"
                    onClick={() => setTrackModal(!trackModal)}
                  >
                    TRACK
                  </Button>

                </InputGroup>
              </div>
            </div>

            {/* mobile-button */}
            <div className="bookShipment">
              <button className='bookShipmentBttn' onClick={() => setShipmentModal(true)} >BOOK A SHIPMENT</button>
            </div>
          </div>
        </div>
        <Row className="blueRow g-0">
          <div className="blueRowList mt-3">
            <div className="book-shipment me-3 d-none d-sm-block mt-4">
              <button className='book-ship-bttn' onClick={() => setShipmentModal(true)}>BOOK A SHIPMENT</button>
            </div>
            <InputGroup className=" mt-4">
              <Form.Control
                className="input-bar"
                placeholder="Enter the Track ID to track Shipment"
                value={message} onChange={(e) => setMessage(e.target.value)}
              />
              <Button
                id="basic-addon2"
                className="track-button"
                onClick={() => setTrackModal(!trackModal)}
              >
                TRACK
              </Button>

            </InputGroup>
            <div className="rate-calculator ms-3 mt-4 d-none d-sm-block  ">
              <a href="#">
                <button className="calculator">RATE CALCULATOR</button>
              </a>
            </div>
          </div>
          <div className="CallUsText">
            <div className="callus-list">
              <div className="info-text ">CALL US :</div>
              <div className="info-detail ms-1">
                050 871 7038 / 050 521 6943
              </div>
            </div>
            <div className="address-list">
              <div className="info-text">ADDRESS :</div>
              <div className="info-detail ms-1">SHARAFIYA-JEDDAH</div>
            </div>
            <div className="mailus-list">
              <div className="info-text">MAIL US :</div>
              <div className="info-detail-mail">saharicargo@mail.com</div>
            </div>
          </div>
        </Row>
        <Row className="blueRowTab g-0">
          <Col sm={8}>
            <div className="tablet-callus mt-4 ms-4">
              <span className="bluerow-textA">CALL US :</span>
              <span className="bluerow-textB">050 871 7038 / 050 521 6943</span>
            </div>
            <div className="first-blue-row mt-2 ms-4">
              <span className="bluerow-textA">MAIL US :</span>
              <span className="bluerow-textB">saharicargo@gmail.com</span>
            </div>
            <div className="first-blue-row mt-2 mb-3 ms-4">
              <span className="bluerow-textA">address :</span>
              <span className="bluerow-textB">Sharafiya,Jeddah</span>
            </div>
          </Col>
          <Col sm={4}>
            <div className="tablet-bluerow-buttn mt-4">
              <a href="#">
                <button className="bluerow-button">RATE CALCULATOR</button>
              </a>
            </div>
            <a href="#">
              <button className="bluerow-button mt-3">GET QUOTE</button>
            </a>
          </Col>
        </Row>
        <Row className="blueRowMobile g-0">
          <div className="mobile-Row-Button mt-4">
            <div className="call-Bttn-Mob">
              <a href="#">
                <button className="call-Bttn-Mobile">CALL</button>
              </a>
            </div>
            <div className="call-Bttn-Mob">
              <a href="#">
                <button className="call-Bttn-Mobile">QUOTE</button>
              </a>
            </div>
          </div>
          <div className="callUsMobile">
            <div className="callUsMobile-Text mt-2 g-0">
              <BsTelephoneFill color="white" size={22}></BsTelephoneFill>
              <span className="callus-Number-Moblie ms-3">
                +966 050 871 7038 / 050 521 6943
              </span>
            </div>
            <div className="callUsMobile-Text mt-3 ">
              <img src={mailimage}></img>
              <span className="mailus-Text-Mobile ms-2">
                saharicargo@gmail.com
              </span>
            </div>
          </div>
        </Row>
      </Container>
      {trackModal ?
        <TrackerModal visible={trackModal} close={() => setTrackModal(false)} inputValue={message} />
        : null}

      {shipmentModal ?
        <Shippingmodal show={shipmentModal} onHide={() => setShipmentModal(false)} /> : null
      }





      {location.pathname === "/trackshipment" ? <Shippingdestination /> : null}
      {location.pathname === "/trackshipment" ? <Partners /> : null}
      {location.pathname === "/trackshipment" ? <Footer /> : null}
      {location.pathname === "/trackshipment" ? <Header /> : null}


    </div>
  );
}
export default Section1;
