const API = {
  BASE_URL: "https://sahari-server.bairuhatech.com/",

  GET_BRANCHES: "branches",

  BOX_STATUS: "boxesNew/box/",

  //Countries
  COUNTRIES_LIST: "country", //GET ?order=ASC&page=1&take=10
  COUNTRIES_ACTIONS: "country/", //PUT, POST , DELETE

  //pickups request
  PICKUP_COLLECTION_REQUEST_LIST: "PickupCollectionRequest", //GET ?order=ASC&page=1&take=10
  PICKUP_COLLECTION_REQUEST_ACTIONS: "PickupCollectionRequest/", //PUT, POST , DELETE
};

export default API;
