import React, { useEffect } from "react";
import "./style.css";
import { Container, Form } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Partners from "../cargoPartners";
import Shippingdestination from "../shippingdestination";
import Branches from "../branches";
import Footer from "../footer";
import Header from "../header";



function CourierService() {
  useEffect(() => {
   window.scrollTo(0,0)
  }
  ,[])

  return (
    <Container fluid className="p-0">
      <br />
      <Header />
      <div className="courierServiceImg">
        {/* <img className='courierServiceImg' src={courierServiceImg}/> */}
      </div>

      <Row className="p-0 g-0 ">
        <Col md={6}>
          <div className="domesticColHead">
            <div className="domesticColHeadSub">
              <div className="domesticHeading">DOMESTIC COURIER SERVICE</div>
              <div className="domesticTxt mt-5">
                For deliveries within the Saudi,we offer a full list of domestic
                delivery services to cater to the varying needs of modern
                businesses.
                <br />
                <br />
                All our domestic services are handled completely by First Flight
                staff .We do not outsource any of our processes.
                <br />
                <br />
                With a wide choice of timeframes and costs,you can choose from
                variuos delivery options to suit your requirements.Whether
                you're looking for urgent same-day deliveries,timely next-day
                deliveries
                <br />
                or less urgent options ,we've got it all.Whatever your choice,we
                offer you convenient and fast delivery services,aided by
                real-time tracking system to enable you keep updated about your
                shipment.
              </div>
            </div>
          </div>
        </Col>
        <Col md={6}>
          <div className="domesticFormColHead">
            <div className="domesticFormColHeadSub">
              <div className="domesticFormHeading">
                Domestic Online Contact Form
              </div>
              <Form className="courierForm">
                <div className="nameEmail mt-4">
                  <div className="name">
                    <label>Name</label>
                    <input className="input" type={"text"}></input>
                  </div>
                  <div className="email">
                    <label>e-mail Id</label>
                    <input className="input" type={"email"}></input>
                  </div>
                </div>
                <div className="telephoneSubject mt-2">
                  <div className="telephone">
                    <label>Telephone</label>
                    <input className="input" type={"number"}></input>
                  </div>
                  <div className="subject">
                    <label>Subject</label>
                    <input className="input" type={"text"}></input>
                  </div>
                </div>
                <div className="textAreaHead mt-2">
                  <label className="moreDetails" form="message">
                    More details
                  </label>
                  <textarea
                    id="w3review"
                    name="w3review"
                    rows="4"
                    cols="50"
                    class="message"
                  ></textarea>
                </div>
                {/* verificationCode */}
                <div>
                  {/* <div className='verificationCodeTxt mt-2'>Verification Code</div>
                  <div className='verificationCode'>43738</div> */}
                  <div className="inputAndBtn">
                    {/* <input className='verifcationInput'></input> */}
                    <div className="submitBtnDomesticFormHead">
                      <button className="submitBtnDomesticForm">SUBMIT</button>
                    </div>
                  </div>
                </div>
                {/* verificationCode */}
              </Form>
            </div>
          </div>
        </Col>
      </Row>

      <div className="courierFeatuesBg">
        <Row className="p-0 g-0">
          <Col md={6} xs={12}>
            <div className="domesticFeaturesHead">
              <div className="domesticFeaturesHeadSub">
                <div className="domesticFeaturesHeading">
                  OUR DOMESTIC COURIER SERVICE FEATURES
                </div>
                <div className="domesticFeaturesSubHeading">
                  Next-day Delivery
                </div>
                <div className="DomesticFeaturesTxt">
                  Next-day Delivery is the most economical pick-up delivery
                  service for the regular movement of consignments in the Saudi.
                  One of our most popular domestic services, we have the
                  capacity and resources in place to deliver single packages to
                  hundred packages within UAE, the very next day. We assure you
                  of timely deliveries, every time, with real-time tracking to
                  ensure that your package arrives at the consignee destination
                  within timelines. Book your order from the comfort of your
                  office or home and rest assured that your package will be
                  collected from your doorstep and delivered to the right
                  location the very next day itself.
                </div>
              </div>
            </div>
          </Col>
          <Col md={6} xs={12}>
            <div className="domesticFeaturesHead">
              <div className="domesticFeaturesHeadSub">
                <div className="domesticFeaturesSubHeading">
                  Consignee Only Delivery
                </div>
                <div className="DomesticFeaturesTxt">
                  A value-added service from First Flight, Consignee Only
                  Delivery is for customers who want to deliver the package to a
                  specific recipient only. The package is handed over to the
                  receiver only after thorough verification of his id proof.
                  This service is especially used for the delivery of documents
                  and financial instruments like bank cards check books, pin
                  cards delivery etc. With more than 3000 satisfied customers
                  worldwide, you can be sure of prompt and safe delivery of your
                  sensitive documents. Our in-house software also allows you to
                  track your parcel real-time, so you know when your package
                  will reach the receiver.
                </div>
              </div>
            </div>
          </Col>
        </Row>

        <Row className="p-0 g-0">
          <Col md={6} xs={12}>
            <div className="domesticFeaturesHead">
              <div className="domesticFeaturesHeadSub">
                <div className="domesticFeaturesSubHeading">
                  Same-day Service
                </div>
                <div className="DomesticFeaturesTxt">
                  When you need shipments to reach their destination in the
                  shortest possible time frame, rely on Same-day Services for
                  your mission-critical shipment. We assure you of immediate
                  pickup and door delivery of your shipment. Ideal for
                  time-sensitive shipments, it can be customized for your
                  requirements. The Same-day Service is fully flexible to suit
                  your business requirements and our team will be available to
                  handle the shipment from pickup to delivery. Our in-house
                  software also allows you to track your shipment real time.
                </div>
              </div>
            </div>
          </Col>
          <Col md={6} xs={12}>
            <div className="domesticFeaturesHead">
              <div className="domesticFeaturesHeadSub">
                <div className="domesticFeaturesSubHeading">
                  Cheque Collection
                </div>
                <div className="DomesticFeaturesTxt">
                  Rely on First Flight for the safe and secure collection and
                  delivery of your cheques. First Flight specialists will pick
                  your cheque from your payer and deliver to your office. Our
                  Cheque Collection service is available all across the UAE. Our
                  Cheque Collection service saves you time as you save on the
                  postage time. It will also increase your revenue with the
                  additional interest earned. You can count on First Flight for
                  absolute integrity and dependency for your crucial financial
                  transactions.
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="p-0 g-0">
          <Col md={6} xs={12}>
            <div className="domesticFeaturesHead">
              <div className="domesticFeaturesHeadSub">
                <div className="domesticFeaturesSubHeading">Bullet Service</div>
                <div className="DomesticFeaturesTxt">
                  Don't compromise on your productivity! With First Flight's
                  Bullet Service, your package will be shipped by the fastest
                  possible route. With our dedicated, time collection and
                  delivery service worldwide, we serve your requirements even
                  out of our regular work times. Our specialists will manage
                  your shipment from start to finish, ensuring the express
                  delivery of your documents and parcels. Bullet Service assures
                  extremely urgent deliveries with 1 hour delivery within the
                  emirate and across the emirates within 3-4 hours.
                </div>
              </div>
            </div><br /><br />
          </Col>
          <Col md={6} xs={12}>
            <div className="domesticFeaturesHead">
              <div className="domesticFeaturesHeadSub">
                <div className="domesticFeaturesSubHeading">Return Service</div>
                <div className="DomesticFeaturesTxt">
                  First Flight's Return Service is a two-way service which will
                  pick up the item to be returned and then deliver to the
                  sender. Flexible return options provide greater convenience,
                  faster turnaround times and the trustworthy and reliable First
                  Flight service. Give your business a competitive edge over
                  your competition and gain market prominence with our Return
                  service.
                </div>
              </div>
            </div><br /><br />
          </Col>
        </Row>
      </div>

      <Row className="p-0 g-0 mt-5">
        <Col md={6}>
          <div className="domesticColHead">
            <div className="domesticColHeadSub">
              <div className="domesticHeading">
                INTERNATIONAL COURIER SERVICE
              </div>
              <div className="domesticTxtSemiBold mt-5">
                Take your business to the next level with our International
                Services. From export service of documents, packages and more,
                across the globe to Import Express and Third Country Services,
                our range of International Services cater to your every need and
                is the best way to send or receive parcels to any destination in
                the world.
              </div>
              <div className="domesticTxt mt-3">
                We cater to the demands of growing businesses which often
                require documents and packages to be delivered within the
                shortest possible time windows. First Flight's International
                Services is the most effective logistical solutions with the
                promise of 3-day delivery to any place on the globe. We
                guarantee the fastest turnaround on our all international
                shipping services, with every parcel being checked thoroughly,
                to ensure accurate, swift and secure delivery. Our operations
                span every corner of the world with real-time tracking of your
                shipments, every step of the way, with our in-house tracking
                software. Whatever your requirement, be it an urgent delivery or
                an expensive parcel, First Flight courier specialists will be on
                the job to ensure that your consignment reaches its intended
                destination on time and at the best rates in the industry.
              </div>
            </div>
          </div>
        </Col>
        <Col md={6}>
          <div className="domesticFormColHead">
            <div className="domesticFormColHeadSub">
              <div className="domesticFormHeading">
                International Online Contact Form
              </div>
              <Form className="courierForm">
                <div className="nameEmail mt-4">
                  <div className="name">
                    <label>Name</label>
                    <input className="input" type={"text"}></input>
                  </div>
                  <div className="email">
                    <label>e-mail Id</label>
                    <input className="input" type={"email"}></input>
                  </div>
                </div>
                <div className="telephoneSubject mt-2">
                  <div className="telephone">
                    <label>Telephone</label>
                    <input className="input" type={"number"}></input>
                  </div>
                  <div className="subject">
                    <label>Subject</label>
                    <input className="input" type={"text"}></input>
                  </div>
                </div>
                <div className="textAreaHead mt-2">
                  <label className="moreDetails" form="message">
                    More details
                  </label>
                  <textarea
                    id="w3review"
                    name="w3review"
                    rows="4"
                    cols="50"
                    class="message"
                  ></textarea>
                </div>
                <div>

                  <div className="inputAndBtn">
                    <div className="submitBtnDomesticFormHead">
                      <button className="submitBtnDomesticForm">SUBMIT</button>
                    </div>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </Col>
      </Row>

      <div className="courierFeatuesBg mt-4">
        <Row className="p-0 g-0">
          <Col md={6} xs={12}>
            <div className="domesticFeaturesHead">
              <div className="domesticFeaturesHeadSub">
                <div className="domesticFeaturesHeading">
                  OUR INTERNATIONAL COURIER SERVICE FEATURES
                </div>
                <div className="domesticFeaturesSubHeading">Export Express</div>
                <div className="DomesticFeaturesTxt">
                  Choose First Flight Export Express for versatile and reliable
                  delivery options. With guaranteed on-time deliveries for
                  time-critical international shipments that need immediate
                  pick-up and door delivery, our Export Express can be
                  customized to suit your requirement. We ship anything from
                  documents to parcels of 500 grams with no upper limit on the
                  weight of goods. We employ the fastest modes of transportation
                  to any global destination within the shortest time possible.
                  Full flexibility to cater to your emergency shipment needs,
                  First Flight specialists will be available throughout the
                  shipment handling process from pick-up to delivery. Our global
                  reach and teams deployed across the globe, ensure fast
                  delivery with end-to-end tracking and customs clearance.
                </div>
              </div>
            </div>
          </Col>
          <Col md={6} xs={12}>
            <div className="domesticFeaturesHead">
              <div className="domesticFeaturesHeadSub">
                <div className="domesticFeaturesSubHeading">
                  Third Country Service
                </div>
                <div className="DomesticFeaturesTxt">
                  Today's businesses require shipments from one country to
                  another, without passing through their country of domicile.
                  First Flight's Third Country Shipment Handling Services
                  enables us to provide our customers with reliable, affordable
                  and timely cross trade solutions. With our highly credible
                  network of associates at various strategic locations across
                  the globe, our solutions save you time, costs by eliminating
                  the needs for storage and additional steps in your supply
                  chain Our Third Country Shipment Handling specialists take
                  complete control of the transportation from origin till
                  delivery.
                  <br />
                  They work round the clock to coordinate, schedule and
                  supervise connecting movements and timely delivery of
                  shipments. Our time-bound, guaranteed Third Country Deliveries
                  can be tracked real-time with our in-house software. Choose
                  First Flight's Third Country Shipment Handling Services and
                  eliminate the need for local brokers or forwarders to get
                  involved in any part of the process.
                </div>
              </div>
            </div>
          </Col>
        </Row>

        <Row className="p-0 g-0">
          <Col md={6} xs={12}>
            <div className="domesticFeaturesHead">
              <div className="domesticFeaturesHeadSub">
                <div className="domesticFeaturesSubHeading">Import Express</div>
                <div className="DomesticFeaturesTxt">
                  A global solution for importing anything from time-sensitive
                  documents to spare parts and parcels small or big, we assure
                  door-to-door pick-up and delivery from around the world to
                  serve your business needs. First Flight's Import Express ships
                  the imports that are requested by you from your global
                  suppliers right to your door. Simply give us the details of
                  your shipment and we will take care of the rest. With more
                  than 3000 happy customers across the world, you can be assured
                  of speedy, secure and on-time delivery. Our in-house software
                  also allows end-to-end tracking of your shipment for the point
                  of pick-up to the time it is delivered at your doorstep.
                </div>
              </div>
            </div><br /><br />
          </Col>
          <Col md={6} xs={12}></Col>
        </Row>
      </div>
      <Partners />
      <Shippingdestination />
      <Branches white={true} />
      <Footer />
    </Container>

  );
}

export default CourierService;
