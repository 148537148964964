import './App.css';
import './style1.css';
import Header from './header';
import Section1 from './section1';
import Footer from './footer';
import Service from './benefits';
import About from './AboutUs';
import Flocklifter from './flocklifter';
import Sectionglad from './gladtohelpsection';
import Sectionshippingareas from './shippingareas';
import ReviewBox from './reviewBox';
import Freequotes from './freequotes';
import Shippingdestination from './shippingdestination';
import Rating from './ratingSection';
import Partners from './cargoPartners';
import Branches from './branches';
import MapLocation from './maplocation';

function App() {
  return (
    <div className="App">
      <Header />
      <Section1 />
      <Service/>
      <About/>
      <Flocklifter />
      <Freequotes />
      {/* <ReviewBox/> */}
      {/* <Sectionshippingareas /> */}
      <Shippingdestination/>  
      {/* <Rating/> */}
      <Partners/>
      <Branches/>
      <Sectionglad />
      {/* <MapLocation/> */}
      <Footer />
    </div>
  );
}

export default App;
