import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./style.css";
import indiaImage from "../asset/flags/india.jpg";
import pakistanImage from "../asset/flags/pakistan.jpg";
import nepalImage from "../asset/flags/nepal 1.jpg";
import indonesia from "../asset/flags/indonesia.jpg";
import philipine from "../asset/flags/philipines.jpg";
import srilanka from "../asset/flags/sri-lanka.jpg";
import bangladesh from "../asset/flags/bangladesh.jpg";
import brunei from "../asset/flags/brunei.jpg";

function Shippingdestination() {
  return (
    <Container fluid className="destinationCountryFlag p-0 ">
      <div className="destinationHeadText pt-5 ">
        <span className="shippingDestination">Our Shipping Destinations</span>
        <div className="yyy">
          <div className="countryFlagBox">
            {/* <div className='kkk'> */}
            <div className="imageIndiaA mb-5">
              <div className="countryFlagDiv">
                <img className="countryFlag" src={indiaImage}></img>
                <span className="flagText ">India</span>
              </div>
              <div className="countryFlagDiv">
                <img className="countryFlag" src={philipine}></img>
                <span className="flagText ">Philippines</span>
              </div>
            </div>
            <div className="imageIndiaA">
              <div className="countryFlagDiv">
                <img className="countryFlag" src={pakistanImage}></img>
                <span className="flagText">Pakistan</span>
              </div>
              <div className="countryFlagDiv">
                <img className="countryFlag" src={srilanka}></img>
                <span className="flagText ">Srilanka</span>
              </div>
            </div>
          </div>
          {/* </div> */}
          {/* <div className='new'> */}

          <div className="imageIndiaA">
            <div className="countryFlagBox">
              <img className="countryFlag" src={nepalImage}></img>
              <span className="flagText">Nepal</span>
            </div>
            <div className="countryFlagBox">
              <img className="countryFlag" src={bangladesh}></img>
              <span className="flagText ">Bangladesh</span>
            </div>
          </div>
          <div className="imageIndiaA ">
            <div className="countryFlagBox">
              <img className="countryFlag" src={indonesia}></img>
              <span className="flagText">Indonesia</span>
            </div>
            <div className="countryFlagBox">
              <img className="countryFlag" src={brunei}></img>
              <span className="flagText ">Brunie</span>
            </div>
          </div>
          {/* </div> */}
        </div>
      </div>
    </Container>
  );
}

export default Shippingdestination;
