// import React, { useEffect, useState } from "react";
// import Modal from "react-bootstrap/Modal";
// import { MdDoneAll } from "react-icons/md";
// import { AiFillDownCircle } from "react-icons/ai";
// import { AiFillUpCircle } from "react-icons/ai";
// import { IoFileTrayOutline } from "react-icons/io5";
// import axios from "axios";
// import CircularProgress from "@mui/material/CircularProgress";
// import moment from "moment";
// import API from "../config/API";
// import "./style.css";

// function TrackerModal(props) {
//   const [loading, setLoading] = useState(true);
//   const [trackBox, setTrackBox] = useState({});
//   const [invalidBox, setInvalidBox] = useState(false);
//   const [isViewMore, setIsViewMore] = useState(true);

//   useEffect(() => {
//     getBoxStatus(props.inputValue);
//   }, []);

//   let uniqueIds = [];

//   const getBoxStatus = (id) => {
//     setLoading(true);
//     let api = API.BASE_URL + API.BOX_STATUS + id;
//     axios
//       .get(api)
//       .then(function (response) {
//         if (response.status === 200) {
//           let data = response.data;

//           if (data) {
//             setTrackBox(data);
//             setLoading(false);
//           } else {
//             setInvalidBox(true);
//             setLoading(false);
//           }
//         }
//       })
//       .catch(function (error) {
//         console.log(error);
//         setInvalidBox(true);
//         setLoading(false);
//       });
//   };

//   const getlatestStatus = () => {
//     const sortedStatusLabels = trackBox.boxStatus
//       .filter(
//         (item) =>
//           item?.box_statuss?.status_order &&
//           item?.box_statuss?.status_order === "100"
//       )
//       .map((item) => item.box_statuss.status_label)
//       .sort((a, b) => b.id - a.id);
//     const lastStatusLabel =
//       sortedStatusLabels.length > 0 ? sortedStatusLabels.pop() : null;
//     return lastStatusLabel ? lastStatusLabel : "NOT AVAILABLE";
//   };

//   return (
//     <div>
//       <Modal size="xl" show={props.visible} onHide={() => props.close()}>
//         <Modal.Header closeButton></Modal.Header>
//         <Modal.Body>
//           <div className="trackModalMainBody">
//             <div className="trackHead">
//               <div className="trackHeadText">Shipment Tracking Details</div>
//             </div>

//             {loading ? (
//               <div className="TrackModalLoader">
//                 <div className="trackLoaderItems">
//                   <CircularProgress />
//                   <div>Loading...</div>
//                 </div>
//               </div>
//             ) : (
//               <>
//                 {invalidBox ? (
//                   <div className="noBoxFound">
//                     <IoFileTrayOutline size={60} className="emptyBoxIcon" />
//                     <div>No Box Found !</div>
//                   </div>
//                 ) : (
//                   <div className="trackModalBody">
//                     <div className="trackSection1">
//                       <div className="section1Col1">
//                         <div>
//                           <MdDoneAll
//                             color="#fff"
//                             size={50}
//                             className="checkIcon"
//                           />
//                         </div>
//                         <div className="TrackLine" />
//                       </div>
//                       <div className="section1Col2">
//                         <div className="S1Col2Item">
//                           <div className="col2Text1">BOX NUMBER</div>
//                           <div className="col2Text2">
//                             {trackBox && trackBox.box_number}
//                           </div>
//                         </div>
//                         <div className="S1Col2Item">
//                           <div className="col2Text1">STATUS</div>
//                           <div className="col2Text2">
//                             {trackBox.ststuss.id === "19"
//                               ? trackBox &&
//                                 Number(
//                                   trackBox?.ststuss &&
//                                     trackBox?.ststuss?.status_order &&
//                                     trackBox?.ststuss?.status_order
//                                 ) === 100
//                                 ? `FORWARDED TO THIRD PARTY WITH TRIP NO : ${trackBox.trip_no}`
//                                 : `${getlatestStatus()}`
//                               : `${trackBox.ststuss?.status_label}`}
//                             {trackBox?.boxStatus?.map((status, index) =>
//                               status?.url && status?.docket ? (
//                                 <span key={index}>
//                                   {status?.agent} &nbsp;
//                                   <a
//                                     key={index}
//                                     target="_blank"
//                                     rel="noopener noreferrer"
//                                     href={`${status.url}${status.docket}`}
//                                   >
//                                     Track
//                                   </a>
//                                 </span>
//                               ) : null
//                             )}
//                           </div>
//                         </div>
//                         <div className="S1Col2Item">
//                           <div className="col2Text1">TOTAL WEIGHT</div>
//                           <div className="col2Text2">
//                             {trackBox && trackBox.weight} KG
//                           </div>
//                         </div>
//                       </div>

//                       <div className="section1Col2">
//                         <div className="S1Col2Item">
//                           <div className="col2Text1">SERVICE TYPE</div>
//                           <div className="col2Text2">
//                             {trackBox && trackBox.service?.name}
//                           </div>
//                         </div>
//                         <div className="S1Col2Item">
//                           <div className="col2Text1">BRANCH NAME</div>
//                           <div className="col2Text2">
//                             {trackBox &&
//                               trackBox.created_user &&
//                               trackBox.created_user?.branch?.name &&
//                               trackBox.created_user.branch.name.toUpperCase()}
//                           </div>
//                         </div>

//                         <div className="S1Col2Item">
//                           <div className="col2Text1">BOOKED DATE</div>
//                           <div className="col2Text2">
//                             {trackBox &&
//                               trackBox.boxStatus &&
//                               trackBox.boxStatus.length > 0 &&
//                               moment(
//                                 trackBox.boxStatus[
//                                   trackBox.boxStatus.length - 1
//                                 ].created_at
//                               ).format("MMM Do YYYY, h:mm:ss A")}
//                           </div>
//                         </div>
//                       </div>
//                     </div>
//                     <div className="S1Col2Itemline333">
//                       <div className="arrowCntrLine">
//                         <div
//                           className="arrowIConDiv"
//                           onClick={() => setIsViewMore(!isViewMore)}
//                         >
//                           {isViewMore ? (
//                             <AiFillUpCircle
//                               size={40}
//                               onClick={() => setIsViewMore(true)}
//                               style={{ color: "#1376ea" }}
//                             />
//                           ) : (
//                             <AiFillDownCircle
//                               size={40}
//                               onClick={() => setIsViewMore(false)}
//                               style={{ color: "#1376ea" }}
//                             />
//                           )}
//                         </div>
//                       </div>
//                     </div>
//                     {isViewMore ? (
//                       <div className="tracksectionMainBox">
//                         {trackBox &&
//                           trackBox.boxStatus
//                             ?.sort((a, b) => b.id - a.id)
//                             .map((status, idx) => {
//                               if (uniqueIds.includes(status.box_statuss.id)) {
//                                 return null;
//                               } else {
//                                 // uniqueIds.push(status.box_statuss.id);
//                                 if (
//                                   status?.box_statuss?.status_order === "100"
//                                 ) {
//                                   return (
//                                     <div className="trackLineSectionBox">
//                                       <div className="leftSection">
//                                         <div className="trackLineArrow">
//                                           <div className="trackLineA">
//                                             <div className="arrowIcon">
//                                               <AiFillUpCircle
//                                                 style={{ color: "#95a4bb" }}
//                                               />
//                                             </div>
//                                           </div>
//                                         </div>
//                                       </div>
//                                       <div className="rightSectionQ">
//                                         <div className="rightSection">
//                                           <div className="rightSectionBoxA">
//                                             <div className="segmentTxt">
//                                               {status?.box_status === "19"
//                                                 ? "OUT FOR DELIVERY"
//                                                 : status?.box_status === "13"
//                                                 ? `BOX RECEIVED AT ${status?.user?.branch.name}`
//                                                 : status?.box_status === "39" ||
//                                                   status?.box_status === "41"
//                                                 ? `${status?.remarks}`
//                                                 : status.box_statuss
//                                                     ?.status_label}

//                                               {status?.url ? (
//                                                 <>
//                                                   {status?.agent}
//                                                   &nbsp;
//                                                   <a
//                                                     target="_blank"
//                                                     rel="noopener noreferrer"
//                                                     href={`${status.url}${status.docket}`}
//                                                   >
//                                                     Track
//                                                   </a>
//                                                 </>
//                                               ) : null}
//                                               {/* {status?.url ? (
//                                                 <a
//                                                   target="_blank"
//                                                   rel="noopener noreferrer"
//                                                   href={`${status.url}${status.docket}`}
//                                                 >
//                                                   {status?.agent}
//                                                 </a>
//                                               ) : null} */}
//                                             </div>
//                                             <div className="segmentDate">
//                                               {status.created_at
//                                                 ? moment(
//                                                     status.created_at &&
//                                                       status.created_at
//                                                   )?.format(
//                                                     "MMM Do YYYY, h:mm:ss a"
//                                                   )
//                                                 : "Date and time not available"}
//                                             </div>

//                                             {status.box_status === "39" ||
//                                             status.box_status === "44" ||
//                                             status.box_status ===
//                                               "43" ? null : (
//                                               <div className="segmentLocalTime">
//                                                 {status?.user?.branch?.name}
//                                               </div>
//                                             )}
//                                           </div>
//                                         </div>
//                                       </div>
//                                     </div>
//                                   );
//                                 } else {
//                                   return null;
//                                 }
//                               }
//                             })}

//                         <div className="trackLineSectionLocation"></div>
//                       </div>
//                     ) : null}
//                   </div>
//                 )}
//               </>
//             )}
//           </div>
//         </Modal.Body>
//       </Modal>
//     </div>
//   );
// }

// export default TrackerModal;
import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { MdDoneAll } from "react-icons/md";
import { AiFillDownCircle } from "react-icons/ai";
import { AiFillUpCircle } from "react-icons/ai";
import { IoFileTrayOutline } from "react-icons/io5";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import moment from "moment";
import API from "../config/API";
import "./style.css";

function TrackerModal(props) {
  const [loading, setLoading] = useState(true);
  const [trackBox, setTrackBox] = useState({});
  const [invalidBox, setInvalidBox] = useState(false);
  const [isViewMore, setIsViewMore] = useState(false);

  let uniqueIds = [];

  useEffect(() => {
    getBoxStatus(props.inputValue);
  }, []);

  const getBoxStatus = (id) => {
    setLoading(true);
    let api = API.BASE_URL + API.BOX_STATUS + id;
    axios
      .get(api)
      .then(function (response) {
        if (response.status === 200) {
          let data = response.data;

          if (data) {
            setTrackBox(data);
            setLoading(false);
          } else {
            setInvalidBox(true);
            setLoading(false);
          }
        }
      })
      .catch(function (error) {
        console.log(error);
        setInvalidBox(true);
        setLoading(false);
      });
  };

  const sortBoxStatus = (boxStatus) => {
    return boxStatus?.sort((a, b) => b.id - a.id);
  };

  const getlatestStatus = () => {
    const sortedStatusLabels = trackBox.boxStatus
      .filter(
        (item) =>
          item?.box_statuss?.status_order &&
          item?.box_statuss?.status_order === "100"
      )
      .map((item) => item.box_statuss.status_label)
      .sort((a, b) => b.id - a.id);
    const lastStatusLabel =
      sortedStatusLabels.length > 0 ? sortedStatusLabels.pop() : null;
    return lastStatusLabel ? lastStatusLabel : "NOT AVAILABLE";
  };

  useEffect(() => {
    if (trackBox?.boxStatus) {
      setTrackBox((prevBox) => ({
        ...prevBox,
        boxStatus: sortBoxStatus(prevBox.boxStatus),
      }));
    }
  }, [trackBox]);

  const toggleViewMore = () => {
    setIsViewMore(!isViewMore);

    if (trackBox?.boxStatus) {
      setTrackBox((prevBox) => ({
        ...prevBox,
        boxStatus: sortBoxStatus(prevBox.boxStatus),
      }));
    }
  };

  return (
    <div>
      <Modal size="xl" show={props.visible} onHide={() => props.close()}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="trackModalMainBody">
            <div className="trackHead">
              <div className="trackHeadText">Shipment Tracking Details</div>
            </div>

            {loading ? (
              <div className="TrackModalLoader">
                <div className="trackLoaderItems">
                  <CircularProgress />
                  <div>Loading...</div>
                </div>
              </div>
            ) : (
              <>
                {invalidBox ? (
                  <div className="noBoxFound">
                    <IoFileTrayOutline size={60} className="emptyBoxIcon" />
                    <div>No Box Found !</div>
                  </div>
                ) : (
                  <div className="trackModalBody">
                    <div className="trackSection1">
                      <div className="section1Col1">
                        <div>
                          <MdDoneAll
                            color="#fff"
                            size={50}
                            className="checkIcon"
                          />
                        </div>
                        <div className="TrackLine" />
                      </div>
                      <div className="section1Col2">
                        <div className="S1Col2Item">
                          <div className="col2Text1">BOX NUMBER</div>
                          <div className="col2Text2">
                            {trackBox && trackBox.box_number}
                          </div>
                        </div>
                        <div className="S1Col2Item">
                          <div className="col2Text1">STATUS</div>
                          <div className="col2Text2">
                            {trackBox.ststuss.id === "19"
                              ? trackBox &&
                                Number(
                                  trackBox?.ststuss &&
                                    trackBox?.ststuss?.status_order &&
                                    trackBox?.ststuss?.status_order
                                ) === 100
                                ? `FORWARDED TO THIRD PARTY WITH TRIP NO : ${trackBox.trip_no}`
                                : `${getlatestStatus()}`
                              : `${trackBox.ststuss?.status_label}`}
                            {trackBox?.boxStatus?.map((status, index) =>
                              status?.url && status?.docket ? (
                                <span key={index}>
                                  {status?.agent} &nbsp;
                                  <a
                                    key={index}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={`${status.url}${status.docket}`}
                                  >
                                    Track
                                  </a>
                                </span>
                              ) : null
                            )}
                          </div>
                        </div>
                        <div className="S1Col2Item">
                          <div className="col2Text1">TOTAL WEIGHT</div>
                          <div className="col2Text2">
                            {trackBox && trackBox.weight} KG
                          </div>
                        </div>
                      </div>

                      <div className="section1Col2">
                        <div className="S1Col2Item">
                          <div className="col2Text1">SERVICE TYPE</div>
                          <div className="col2Text2">
                            {trackBox && trackBox.service?.name}
                          </div>
                        </div>
                        <div className="S1Col2Item">
                          <div className="col2Text1">BRANCH NAME</div>
                          <div className="col2Text2">
                            {trackBox &&
                              trackBox.created_user &&
                              trackBox.created_user?.branch?.name &&
                              trackBox.created_user.branch.name.toUpperCase()}
                          </div>
                        </div>

                        <div className="S1Col2Item">
                          <div className="col2Text1">BOOKED DATE</div>
                          <div className="col2Text2">
                            {trackBox &&
                              trackBox.boxStatus &&
                              trackBox.boxStatus.length > 0 &&
                              moment(
                                trackBox.boxStatus[
                                  trackBox.boxStatus.length - 1
                                ].created_at
                              ).format("MMM Do YYYY, h:mm:ss A")}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="S1Col2Itemline333">
                      <div className="arrowCntrLine">
                        <div className="arrowIConDiv" onClick={toggleViewMore}>
                          {isViewMore ? (
                            <AiFillUpCircle
                              size={40}
                              onClick={() => setIsViewMore(true)}
                              style={{ color: "#1376ea" }}
                            />
                          ) : (
                            <AiFillDownCircle
                              size={40}
                              onClick={() => setIsViewMore(false)}
                              style={{ color: "#1376ea" }}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                    {isViewMore ? (
                      <div className="tracksectionMainBox">
                        {trackBox &&
                          trackBox.boxStatus?.map((status, idx) => {
                            if (uniqueIds.includes(status.box_statuss.id)) {
                              return null;
                            } else {
                              if (
                                status?.box_statuss?.status_order === "100" ||
                                status?.box_statuss?.status_order === "3"
                              ) {
                                return (
                                  <div className="trackLineSectionBox">
                                    <div className="leftSection">
                                      <div className="trackLineArrow">
                                        <div className="trackLineA">
                                          <div className="arrowIcon">
                                            <AiFillUpCircle
                                              style={{ color: "#95a4bb" }}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="rightSectionQ">
                                      <div className="rightSection">
                                        <div className="rightSectionBoxA">
                                          <div className="segmentTxt">
                                            {status?.box_status === "19"
                                              ? "OUT FOR DELIVERY"
                                              : status?.box_status === "13"
                                              ? `BOX RECEIVED AT ${status?.user?.branch.name}`
                                              : status?.box_status === "3"
                                              ? `${status?.box_statuss?.status_label} - ${status?.remarks}`
                                              : status?.box_status === "39" ||
                                                status?.box_status === "41" ||
                                                status?.box_status === "3"
                                              ? `${status?.remarks}`
                                              : status?.box_statuss
                                                  ?.status_label}

                                            {status?.url ? (
                                              <>
                                                {status?.agent}
                                                &nbsp;
                                                <a
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                  href={`${status.url}${status.docket}`}
                                                >
                                                  Track
                                                </a>
                                              </>
                                            ) : null}
                                          </div>
                                          <div className="segmentDate">
                                            {status.created_at
                                              ? moment(
                                                  status.created_at &&
                                                    status.created_at
                                                )?.format(
                                                  "MMM Do YYYY, h:mm:ss a"
                                                )
                                              : "Date and time not available"}
                                          </div>

                                          {status.box_status === "39" ||
                                          status.box_status === "44" ||
                                          status.box_status === "43" ? null : (
                                            <div className="segmentLocalTime">
                                              {status?.user?.branch?.name}
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                );
                              } else {
                                return null;
                              }
                            }
                          })}

                        <div className="trackLineSectionLocation"></div>
                      </div>
                    ) : null}
                  </div>
                )}
              </>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default TrackerModal;
