import { Button, Modal } from "antd";
import { useState } from "react";
import "./style.css";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { Pages } from "@material-ui/icons";
import { IoMdCheckmarkCircle } from "react-icons/io";

function LoadingModal(props) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showLoadingModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    props.onHide();
  };

  return (
    <div>
      <Modal
        closable={false}
        open={props.show}
        onOk={props.onHide}
        onCancel={handleCancel}
        footer={null}
        style={{ marginTop: 150,borderRadius:"8%" }}
      >
        <div className="loadingClose-Icon">
          <AiOutlineCloseCircle
            onClick={() => {props.onHide();
            props.handleCancel()
            } }
            
            size={30}
          />
        </div>
        {props.isLoading ? (
          <div className="loadingIcon">
            <CircularProgress size={75} />
          </div>
        ) : (
          <div className="loadingIcon">
            <IoMdCheckmarkCircle className="successIcon" />
            <span>SUCCESS</span>
            <div className="referenceTxt">Your Reference no: {props.data?.id && props.data.id}</div>
            <br/>
          </div>
        )}
      </Modal>
    </div>
  );
}

export default LoadingModal;
