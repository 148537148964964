import React, { useEffect, useState } from "react";
import { Button, Form, message, Modal, notification } from "antd";
import { Collapse } from "antd";
import "./style.css";
import { DatePicker } from "antd";
import { Slider } from "antd";
import { Input } from "antd";
import moment from "moment";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import axios from "axios";
import { Select, Space } from "antd";
import { AiOutlineCloseCircle } from "react-icons/ai";
import API from "../config/API";
import LoadingModal from "../loadingModal";

const { TextArea } = Input;

function ShippingModal(props) {
  useEffect(() => {
    BranchesDropdown();
    ContriesDropdown();
  }, []);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [inputValueSenderName, setInputValueSenderName] = useState("");
  const [inputValueSenderPhNo, setInputValueSenderPhNo] = useState();
  const [inputValueSenderAltPhNo, setInputValueSenderAltPhNo] = useState();
  const [inputValueSenderAddress, setInputValueSenderAddress] = useState("");
  const [inputValueRecieverName, setInputValueRecieverName] = useState("");
  const [inputValueRecieverPhNo, setInputValueRecieverPhNo] = useState();
  const [inputValueRecieverAltPhNo, setInputValueRecieverAltPhNo] = useState();
  const [inputValueRecieverCountry, setInputValueRecieverCountry] = useState(
    []
  );
  const [inputValueRecieverAddress, setinputValueRecieverAddress] =
    useState("");
  const [inputValueRecieverState, setinputValueRecieverState] = useState("");
  const [inputValueRecieverDistrict, setinputValueRecieverDistrict] =
    useState("");
  const [inputValueRecieverPin, setinputValueRecieverPin] = useState();
  const [inputValueRecieverPost, setInputValueRecieverPost] = useState();
  const [inputValuePlaceLocation, setInputValuePlaceLocation] = useState([]);
  const [inputValueCount, setInputValueCount] = useState();
  const [inputValueWeight, setInputValueWeight] = useState();
  const [inputValueConvenientDate, setinputValueConvenientDate] = useState();
  const [inputValuePickupPlace, setInputValuePickupPlace] = useState([]);

  const [start, setStart] = useState(props.item && props.item.from_time);
  const [end, setEnd] = useState(props.item && props.item.to_time);
  const [countries, setcountries] = useState([]);
  const [branches, setBranches] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedlocation, setSelectedLocation] = useState("");
  const { Option } = Select;

  const [refNumber, setRefNumber] = useState();

  const [isLoading, setIsLoading] = useState(false);

  const [loadingModal, setLoadingModal] = useState(false);

  const marks = {
    9: {
      label: (
        <>
          <br />
          {start}
        </>
      ),
    },
    23: {
      label: (
        <>
          <br />
          {end}
        </>
      ),
    },
  };

  const onPickTime = (val) => {
    const timestring1 = val[0] * 60;
    let newDate1 = moment
      .utc()
      .startOf("day")
      .add(timestring1, "minutes")
      .format("hh:mm A");
    setStart(newDate1);

    const timestring2 = val[1] * 60;
    let newDate2 = moment
      .utc()
      .startOf("day")
      .add(timestring2, "minutes")
      .format("hh:mm A");
    setEnd(newDate2);
  };

  function recieverCountry(value) {
    setInputValueRecieverCountry(value);
    let country = countries.find((data) => data.code === value);
    setSelectedCountry(country.name);
  }
  function pickupPlace(value) {
    setInputValuePickupPlace(value);
    let branch = branches.find((item) => item.id === value);
    setSelectedLocation(branch.name);
  }

  const onFormSubmit = (val) => {
    setIsLoading(true);
    setLoadingModal(true);

    let obj = {
      sender_name: inputValueSenderName,
      sender_phone: inputValueSenderPhNo,
      sender_phone_alt: inputValueSenderAltPhNo,
      sender_address: inputValueSenderAddress,
      reciver_name: inputValueRecieverName,
      reciver_phone: inputValueRecieverPhNo,
      reciver_alt_phone: inputValueRecieverAltPhNo,
      reciver_address: inputValueRecieverAddress,
      reciver_cnty_id:
        inputValueRecieverCountry > 0
          ? Number(inputValueRecieverCountry)
          : null,
      pickup_branch: Number(inputValuePickupPlace),
      reciver_state: inputValueRecieverState,
      reciver_distrcit: inputValueRecieverDistrict,
      reciver_pincode: inputValueRecieverPin,
      post_office: inputValueRecieverPost,
      pickup_place: selectedlocation,
      pickup_landmark: "string",
      pickup_address: inputValuePickupPlace,
      convenient_datetime: "2023-03-02T13:11:48.335Z",
      approximate_weight: Number(inputValueWeight),
      no_of_box: Number(inputValueCount),
      status: "string",
      active: true,
      from_time: start,
      to_time: end,
    };

    // console.log("Req Obj ==> ", obj);

    let URL = API.BASE_URL + "PickupCollectionRequest";
    axios({
      method: "POST",
      url: URL,
      data: obj,
    })
      .then(function (response) {
        setIsLoading(false);
        if ([200, 201].includes(response.status)) {
          console.log("Uploaded");
          let data = response.data;
          // console.log("dataa===>",data)
          setRefNumber(data);
          message.success("Created Successfully");
        }
      })
      .catch(function (error) {
        setIsLoading(false);
        message.error("Something went wrong....!");
        notification.error({
          message: "Something went wrong....!",
          duration: 3, // in seconds
        });
        console.log(error);
      });
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    props.onHide();
  };
  const { Panel } = Collapse;

  const App = () => {};

  const ContriesDropdown = () => {
    axios
      .get(API.BASE_URL + API.COUNTRIES_LIST + `/list`, {})
      .then(function (res) {
        if (res.status === 200) setcountries(res.data);
      })
      .catch(function (error) {});
  };

  const BranchesDropdown = () => {
    axios
      .get(API.BASE_URL + API.GET_BRANCHES + `/list`, {})
      .then(function (res) {
        if (res.status === 200) {
          setBranches(res.data);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <>
      <Modal
        width={1200}
        title={
          <div className="main">
            <div className="null"></div>
            <div className="modalHeading">New Pickup Request </div>
            <div className="closeIcon">
              <AiOutlineCloseCircle className="closeIconAi" size={30} />
            </div>
          </div>
        }
        open={props.show}
        onOk={props.onHide}
        onCancel={handleCancel}
        footer={false}
      >
        <Form onFinish={onFormSubmit}>
          <div className="collapseHead">
            <Collapse defaultActiveKey={["1"]} accordion>
              <Panel
                className="collapseHeading"
                header="SHIPMENT DETAILS"
                key="1"
              >
                <Row>
                  <Col md={6} sm={12}>
                    <label className="labelHeading">BOX COUNT</label>
                    <Form.Item
                      name="no_of_box"
                      rules={[{ required: true, message: "required" }]}
                    >
                      <Input
                        size="large"
                        value={inputValueCount}
                        onChange={(e) => setInputValueCount(e.target.value)}
                        type="number"
                      />
                    </Form.Item>
                  </Col>
                  <Col md={6} sm={12}>
                    <label className="labelHeading">APPROX WEIGHT</label>
                    <Form.Item
                      name="approximate_weight"
                      rules={[{ required: true, message: "required" }]}
                    >
                      <Input
                        size="large"
                        value={inputValueWeight}
                        onChange={(e) => setInputValueWeight(e.target.value)}
                        type="text"
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col md={6} sm={12}>
                    <div className="datePicker">
                      <label className="labelHeading">
                        CONVENIENT PICKUP TIME
                      </label>
                      <Form.Item
                        name="convenient_datetime"
                        // required
                        rules={[{ required: true, message: "required" }]}
                      >
                        <DatePicker
                          format="DD-MM-YYYY"
                          onChange={(date, dateString) => {
                            setinputValueConvenientDate(dateString);
                          }}
                        />
                      </Form.Item>
                    </div>
                  </Col>
                  <Col md={6} sm={12}>
                    <label className="labelHeading">LOCATION</label>
                    <Form.Item
                      name="pickup_place"
                      rules={[{ required: true, message: "required" }]}
                    >
                      <Select
                        value={inputValuePickupPlace}
                        onChange={pickupPlace}
                        placeholder="SELECT BRANCH"
                      >
                        {branches &&
                          branches.map((item, index) => {
                            return (
                              <Option key={item.id} val={item.id}>
                                {item.name}
                              </Option>
                            );
                          })}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <div className="mt-2">
                  <label className="labelHeading">TIME</label>
                  <Form.Item name="time">
                    <div className="sliderHead mt-2">
                      <Slider
                        range
                        marks={marks}
                        onChange={onPickTime}
                        max={23}
                        min={9}
                        step={0.25}
                      />
                    </div>
                  </Form.Item>
                </div>
              </Panel>
              <Panel className="collapseHeading" header="SENDER" key="2">
                <Row>
                  <Col md={6}>
                    <div>
                      <label className="labelHeading">Sender Name</label>
                      <Form.Item
                        name="sender_name"
                        rules={[{ required: true, message: "required" }]}
                      >
                        <Input
                          size="large"
                          type="text"
                          value={inputValueSenderName}
                          onChange={(e) =>
                            setInputValueSenderName(e.target.value)
                          }
                          placeholder="Enter your full name here"
                        />
                      </Form.Item>
                    </div>
                    <Row className="mt-3">
                      <Col md={6} sm={12}>
                        <label className="labelHeading">Phone Number</label>
                        <Form.Item
                          name="sender_phone"
                          rules={[{ required: true, message: "required" }]}
                        >
                          <Input
                            size="large"
                            type="number"
                            value={inputValueSenderPhNo}
                            onChange={(e) =>
                              setInputValueSenderPhNo(e.target.value)
                            }
                            placeholder="Enter your phone number"
                          />
                        </Form.Item>
                      </Col>
                      <Col md={6} sm={12}>
                        <label className="labelHeading">Alt.Phone Number</label>
                        <Form.Item name="sender_phone_alt">
                          <Input
                            size="large"
                            type="number"
                            value={inputValueSenderAltPhNo}
                            onChange={(e) =>
                              setInputValueSenderAltPhNo(e.target.value)
                            }
                            placeholder="Enter your alt.phone number"
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                  <Col md={6}>
                    <div>
                      <label className="labelHeading">Sender Address</label>
                      <Form.Item
                        name="sender_address"
                        rules={[{ required: true, message: "required" }]}
                      >
                        <TextArea
                          id="myTextArea"
                          type="text"
                          value={inputValueSenderAddress}
                          onChange={(e) =>
                            setInputValueSenderAddress(e.target.value)
                          }
                          placeholder="Enter your full address with post and code"
                          rows={5}
                        />
                      </Form.Item>
                    </div>
                  </Col>
                </Row>
              </Panel>
              <Panel className="collapseHeading" header="RECIEVER" key="3">
                <div>
                  <Row>
                    <Col md={7}>
                      <div>
                        <label className="labelHeading">Reciever Name</label>
                        <Form.Item name="reciver_name">
                          <Input
                            size="large"
                            type="text"
                            onChange={(e) =>
                              setInputValueRecieverName(e.target.value)
                            }
                            value={inputValueRecieverName}
                            placeholder="Enter your full name here"
                          />
                        </Form.Item>
                      </div>
                      <Row className="mt-3">
                        <Col md={6} sm={12}>
                          <label className="labelHeading">Country</label>
                          <Form.Item name="reciver_cnty_id">
                            <Select
                              onChange={recieverCountry}
                              value={inputValueRecieverCountry}
                              placeholder="Select a country"
                              style={{
                                width: 120,
                              }}
                            >
                              {countries &&
                                countries.map((item) => {
                                  return (
                                    <Option key={item.code} val={item.code}>
                                      {item.name}
                                    </Option>
                                  );
                                })}
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col md={6} sm={12}>
                          <label className="labelHeading">State</label>
                          <Form.Item name="reciver_state">
                            <Input
                              size="large"
                              value={inputValueRecieverState}
                              onChange={(e) =>
                                setinputValueRecieverState(e.target.value)
                              }
                              type="text"
                              placeholder="Enter your state"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>
                    <Col md={5}>
                      <div>
                        <label className="labelHeading">Reciever Address</label>
                        <Form.Item name="reciver_address">
                          <TextArea
                            id="myTextArea"
                            type="text"
                            value={inputValueRecieverAddress}
                            onChange={(e) =>
                              setinputValueRecieverAddress(e.target.value)
                            }
                            placeholder="Enter your full address with post and code"
                            rows={5}
                          />
                        </Form.Item>
                      </div>
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col md={4} sm={12}>
                      <label className="labelHeading">District</label>
                      <Form.Item name="reciver_distrcit">
                        <Input
                          size="large"
                          value={inputValueRecieverDistrict}
                          onChange={(e) =>
                            setinputValueRecieverDistrict(e.target.value)
                          }
                          type="text"
                          placeholder="Enter your district"
                        />
                      </Form.Item>
                    </Col>
                    <Col md={4} sm={12}>
                      <label className="labelHeading">Post Box</label>
                      <Form.Item name="post_office">
                        <Input
                          size="large"
                          value={inputValueRecieverPost}
                          onChange={(e) =>
                            setInputValueRecieverPost(e.target.value)
                          }
                          type="number"
                          placeholder="Enter post box number"
                        />
                      </Form.Item>
                    </Col>
                    <Col md={4} sm={12}>
                      <label className="labelHeading">Pincode</label>
                      <Form.Item name="reciver_pincode">
                        <Input
                          size="large"
                          value={inputValueRecieverPin}
                          onChange={(e) =>
                            setinputValueRecieverPin(e.target.value)
                          }
                          type="number"
                          placeholder="Enter your pincode"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col md={6} sm={12}>
                      <label className="labelHeading">Phone Number</label>
                      <Form.Item name="reciver_phone">
                        <Input
                          size="large"
                          type="number"
                          value={inputValueRecieverPhNo}
                          onChange={(e) =>
                            setInputValueRecieverPhNo(e.target.value)
                          }
                          placeholder="Enter your phone number"
                        />
                      </Form.Item>
                    </Col>
                    <Col md={6} sm={12}>
                      <label className="labelHeading">Alt.Phone Number</label>
                      <Form.Item name="reciver_alt_phone">
                        <Input
                          size="large"
                          type="number"
                          value={inputValueRecieverAltPhNo}
                          onChange={(e) =>
                            setInputValueRecieverAltPhNo(e.target.value)
                          }
                          placeholder="Enter your alt.phone number"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
              </Panel>

              <Panel className="collapseHeading" header="OVERVIEW" key="4">
                <div>
                  <div className="inputQuestionAndAnswer mt-3">
                    <div className="inputQuestion">Sender Name</div>
                    <div className="inputAnswer">{inputValueSenderName}</div>
                  </div>
                  <div className="inputQuestionAndAnswer mt-3">
                    <div className="inputQuestion">Sender Phone</div>
                    <div className="inputAnswer">{inputValueSenderPhNo}</div>
                  </div>
                  <div className="inputQuestionAndAnswer mt-3">
                    <div className="inputQuestion">Alt.Sender Phone</div>
                    <div className="inputAnswer">{inputValueSenderAltPhNo}</div>
                  </div>
                  <div className="inputQuestionAndAnswer mt-3">
                    <div className="inputQuestion">Sender Address</div>
                    <div className="inputAnswer">{inputValueSenderAddress}</div>
                  </div>

                  <div className="borderLine"></div>

                  <div className="inputQuestionAndAnswer mt-3">
                    <div className="inputQuestion">Reciever Name</div>
                    <div className="inputAnswer">{inputValueRecieverName}</div>
                  </div>
                  <div className="inputQuestionAndAnswer mt-3">
                    <div className="inputQuestion">Reciever Phone</div>
                    <div className="inputAnswer">{inputValueRecieverPhNo}</div>
                  </div>
                  <div className="inputQuestionAndAnswer mt-3">
                    <div className="inputQuestion">Reciever Alt.Phone</div>
                    <div className="inputAnswer">
                      {inputValueRecieverAltPhNo}
                    </div>
                  </div>
                  <div className="inputQuestionAndAnswer mt-3">
                    <div className="inputQuestion">Reciever Address</div>
                    <div className="inputAnswer">
                      {inputValueRecieverAddress}
                    </div>
                  </div>
                  <div className="inputQuestionAndAnswer mt-3">
                    <div className="inputQuestion">Reciever Country</div>
                    <div className="inputAnswer">{selectedCountry}</div>
                  </div>
                  <div className="inputQuestionAndAnswer mt-3">
                    <div className="inputQuestion">Reciever State</div>
                    <div className="inputAnswer">{inputValueRecieverState}</div>
                  </div>
                  <div className="inputQuestionAndAnswer mt-3">
                    <div className="inputQuestion">Reciever District</div>
                    <div className="inputAnswer">
                      {inputValueRecieverDistrict}
                    </div>
                  </div>
                  <div className="inputQuestionAndAnswer mt-3">
                    <div className="inputQuestion">Reciever Pincode</div>
                    <div className="inputAnswer">{inputValueRecieverPin}</div>
                  </div>
                  <div className="inputQuestionAndAnswer mt-3">
                    <div className="inputQuestion">Reciever Post</div>
                    <div className="inputAnswer">{inputValueRecieverPost}</div>
                  </div>

                  <div className="borderLine"></div>

                  <div className="inputQuestionAndAnswer mt-3">
                    <div className="inputQuestion">Pickup Place</div>
                    <div className="inputAnswer">{selectedlocation}</div>
                  </div>
                  <div className="inputQuestionAndAnswer mt-3">
                    <div className="inputQuestion">Approximate weight</div>
                    <div className="inputAnswer">{inputValueWeight}</div>
                  </div>
                  <div className="inputQuestionAndAnswer mt-3">
                    <div className="inputQuestion">Count</div>
                    <div className="inputAnswer">{inputValueCount}</div>
                  </div>
                  <div className="inputQuestionAndAnswer mt-3">
                    <div className="inputQuestion">Convenient Date</div>
                    <div className="inputAnswer">
                      {inputValueConvenientDate}
                    </div>
                  </div>
                  <div className="inputQuestionAndAnswer mt-3">
                    <div className="inputQuestion">
                      Estimated pickup will be
                    </div>
                    <div className="inputAnswer">{`${start}  to  ${end}`}</div>
                  </div>
                </div>
              </Panel>
            </Collapse>
          </div>
          <div className="modalFooter">
            <div className="ModalBtnHead">
              <button className="modalCancelBtn" onClick={handleCancel}>
                Cancel
              </button>
              <Button
                htmlType="submit"
                type="primary"
                className="modalSubmitBtn"
                loading={isLoading}
              >
                SUBMIT
              </Button>
            </div>
          </div>
        </Form>
        {loadingModal ? (
          <LoadingModal
            isLoading={isLoading}
            show={loadingModal}
            onHide={props.onHide}
            data={refNumber}
          />
        ) : null}
      </Modal>
    </>
  );
}

export default ShippingModal;
