import React, { useEffect } from "react";
import "./style.css";
import Header from "../header";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Partners from "../cargoPartners";
import Branches from "../branches";
import Footer from "../footer";


function PackingAndShipping() {
  useEffect(()=>{
    window.scrollTo(0,0)
  },[])
  return (
    <div>
      <Header />
      <div className="packingAndShippingImg"></div>

      <Row className="p-0 g-0">
        <Col md={6} sm={12} xs={12}>
          <div className="domesticColHead">
            <div className="domesticColHeadSub">
              <div className="domesticHeadingPacking">Packing & Shipping</div>
              <div className="domesticTxt mt-5">
                Proper packing and shipping are essential for ensuring that
                goods reach their destination safely and securely. SAHARI
                Cargo's team of skilled packers use high-quality, tested packing
                materials to ensure that your shipments are well-protected
                during transit.<br/><br/> Our packers are experienced in packing a wide
                variety of items, including fragile, hazardous, and oversized
                cargo. They use the appropriate packing methods and materials
                for each type of shipment, ensuring that your goods are secure
                and protected from damage.<br/><br/> In addition to using the best packing
                materials, our team also labels and documents shipments
                correctly, ensuring that your cargo is properly identified and
                tracked throughout the shipping process.<br/><br/> Overall, SAHARI Cargo's
                packing and shipping services provide peace of mind to our
                clients, ensuring that their shipments are handled with care and
                reach their destination safely.
              </div>
            </div>
          </div>
        </Col>
        <Col md={6} sm={12} xs={12}>
        <div className="domesticFormColHead ">
              <div className="domesticFormColHeadSub">
                <div className="domesticFormHeading">Online Contact Form</div>
                <div className="courierForm">
                  
                  <div className="nameEmail mt-4">
                    <div className="name">
                      <label>Name</label>
                      <input className="input" type={"text"}></input>
                    </div>
                    <div className="email">
                      <label>e-mail Id</label>
                      <input className="input" type={"email"}></input>
                    </div>
                  </div>
                  <div className="telephoneSubject mt-2">
                    <div className="telephone">
                      <label>Telephone</label>
                      <input className="input" type={"number"}></input>
                    </div>
                    <div className="subject">
                      <label>Subject</label>
                      <input className="input" type={"text"}></input>
                    </div>
                  </div>
                  <div className="textAreaHead mt-2">
                    <label className="moreDetails" form="message">
                      More details
                    </label>
                    <textarea
                      id="w3review"
                      name="w3review"
                      rows="4"
                      cols="50"
                      class="message"
                    ></textarea>
                  </div>
                  {/* verificationCode */}
                  <div>
                    {/* <div className='verificationCodeTxt mt-2'>Verification Code</div>
                  <div className='verificationCode'>43738</div> */}
                    <div className="inputAndBtn">
                      {/* <input className='verifcationInput'></input> */}
                      <div className="submitBtnDomesticFormHead">
                        <button className="submitBtnDomesticForm">
                          SUBMIT
                        </button>
                      </div>
                    </div>
                  </div>
                  {/* verificationCode */}
                </div>
              </div>
            </div>
        </Col>
      </Row>
      <Partners airport={true}/>
      <Branches white={true}/>
      <Footer/>
    </div>
  );
}

export default PackingAndShipping;
