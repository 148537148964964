import React, { useEffect } from "react";
import "./style.css";
import { Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Header from "../header";
import Shippingdestination from "../shippingdestination";
import Branches from "../branches";
import Footer from "../footer";

function DoorToDoor() {
  useEffect(()=>{
    window.scrollTo(0,0)
  },[])
  return (
    <Container fluid className="p-0">
      <Header />
      <div>
        <div className="doorToDoorImg"></div>

        <Row className="p-0 g-0">
          <Col md={6} sm={12} xs={12}>
            <div className="domesticColHead">
              <div className="domesticColHeadSub">
                <div className="domesticHeadingDoor">Door to Door</div>
                <div className="domesticHeadingDoor"> Pickup & Delivery</div>
                <div className="domesticTxtSemiBold mt-5">WE HAVE DOOR TO DOOR DELIVERY TO 8 COUNTRIES</div>
                <div className="domesticTxtDoor mt-3">
                  As a leader in the logistics industry, SAHARI Cargo is proud
                  to offer premier domestic and international courier services.
                  With a comprehensive network of delivery points and a team of
                  skilled professionals at the ready, we are well-equipped to
                  meet the diverse needs of our esteemed clients. We ship cargos
                  to India, Philippines, Nepal, Bangladesh, Pakistan, Srilanka,
                  Indonesia & Brunei. <br/><br/>
                  In addition to our domestic offerings, we
                  also have a global network that allows us to provide
                  top-quality freight services to destinations around the world.
                  Whether you are shipping a small package or a large shipment,
                  our team is equipped to handle all of your cargo needs with
                  the utmost care and attention.<br/><br/>
                   So if you are in need of a
                  dependable and efficient courier service, look no further than
                  SAHARI Cargo. With our wide-ranging network and unparalleled
                  expertise, we are confident that we can meet and exceed your
                  expectations
                </div>
              </div>
            </div>
          </Col>
          <Col md={6} sm={12} xs={12}>
          <div className="domesticFormColHead">
            <div className="domesticFormColHeadSub">
              <div className="domesticFormHeading">
                Online Contact Form
              </div>
              <div className="courierForm">
                <div className="nameEmail mt-4">
                  <div className="name">
                    <label>Name</label>
                    <input className="input" type={"text"}></input>
                  </div>
                  <div className="email">
                    <label>e-mail Id</label>
                    <input className="input" type={"email"}></input>
                  </div>
                </div>
                <div className="telephoneSubject mt-2">
                  <div className="telephone">
                    <label>Telephone</label>
                    <input className="input" type={"number"}></input>
                  </div>
                  <div className="subject">
                    <label>Subject</label>
                    <input className="input" type={"text"}></input>
                  </div>
                </div>
                <div className="textAreaHead mt-2">
                  <label className="moreDetails" form="message">
                    More details
                  </label>
                  <textarea
                    id="w3review"
                    name="w3review"
                    rows="4"
                    cols="50"
                    class="message"
                  ></textarea>
                </div>
                {/* verificationCode */}
                <div>
                  {/* <div className='verificationCodeTxt mt-2'>Verification Code</div>
                  <div className='verificationCode'>43738</div> */}
                  <div className="inputAndBtn">
                    {/* <input className='verifcationInput'></input> */}
                    <div className="submitBtnDomesticFormHead">
                      <button className="submitBtnDomesticForm">SUBMIT</button>
                    </div>
                  </div>
                </div>
                {/* verificationCode */}
              </div>
            </div>
          </div>
          </Col>
        </Row>
      </div>
      <br/>
      <Shippingdestination/>
      <Branches white={true}/>
      <Footer/>
    </Container>
  );
}

export default DoorToDoor;
