import React from 'react'
import './style.css'
import { Button, Col, Container } from 'react-bootstrap'
import Row from 'react-bootstrap/Row';
import saharilogo from '../asset/svgicons/Sahari logo RW.svg';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Appstore1 from '../asset/image/apple-icon.png';
import android1 from '../asset/image/android-icon.png';
import Locationsvg from '../asset/svgicons/sm location.svg';
import Calliconsvg from '../asset/svgicons/sm phone.svg';
import Mailiconsvg from '../asset/svgicons/sm mail.svg';
import Twittericon from '../asset/svgicons/SM twitter.svg';
import Facebookicon from '../asset/svgicons/SM facebook.svg';
import Instagramicon from '../asset/svgicons/SM instagram.svg';
import { useState } from 'react';
import TrackerModal from '../TrackModal/TrackerModal';



function Footer() {
const [trackModal,setTrackModal] =useState(false)
    return (
        <div>
            <Container fluid className='footer'>
                <div>
                    <Row>
                        <div className='col-md-2'></div>
                        <div className='col-md-8 col-12'>
                            <div className='input-search mb-5 d-flex flex-lg-nowrap flex-wrap'>
                                <InputGroup className="inputGroup pt-4">
                                    <Form.Control className='input-bar'
                                        placeholder="Enter the Track ID to track Shipment"
                                    />
                                    <Button id="basic-addon2"
                                    onClick={() => setTrackModal(!trackModal)} 
                                    className='track-button'>TRACK</Button>
                                </InputGroup>
                                <div className='pt-4 d-flex'>
                                <button className='bookShipmentBtn border rounded ms-3 text-nowrap py-2 text-uppercase'>Book a Shipment</button>
                                <button className='bookShipmentBtn border rounded ms-3 text-nowrap py-2 text-uppercase'>Rate Calculator</button>
                                </div>
                               
                            </div>
                        </div>
                       <div className='col-md-2'></div>
                    </Row>
                    <Row>
                        <Col md={4} xs={12} sm={6}>
                            <div className='footer-logo'>
                                <img className='sahari-logo-footer' src={saharilogo}></img>
                            </div>
                            <div className='app-download' >
                                <span className='download-apptext'>DOWNLOAD APP NOW</span>
                            </div>
                            <div className='appstore-svg'>
                                <p >
                                    <a href='#'>
                                        <img className='apple-store' src={Appstore1}></img></a>
                                </p>
                                <p>
                                    <a href='#'><img className='android-store' src={android1}></img></a>
                                </p>
                            </div>


                        </Col>
                        <Col md={4} xs={12} sm={6}>
                            <div className='footer-list-two'>
                                <div className='location-name'>
                                    <img src={Locationsvg}></img>
                                    <span className='location-text ps-2'>SHARAFIYA-JEDDAH</span>
                                </div>
                                <div className='call-svg mt-3'>
                                    <img src={Calliconsvg}></img>
                                    <span className='location-text ps-2'>050 871 7038 / 050 521 6943</span>
                                </div>
                                <div className='mail-svg mt-3'>
                                    <img src={Mailiconsvg}></img>
                                    <span className='mail-text ps-2'>saharicargo@gmail.com</span>
                                </div>
                                <div className='social-icon-list'>
                                    <div className='we-are-social'>WE ARE SOCIAL</div>
                                    <div className='social-iconsvg mb-3'>
                                        <a href='#'><img className='twitt-icon me-3' src={Twittericon}></img></a>
                                        <a href='#'><img className='fb-icon' src={Facebookicon}></img></a>
                                        <a href='#'><img className='insta-icon ms-3' src={Instagramicon}></img></a>
                                    </div>
                                </div>
                            </div>



                        </Col>
                        <Col md={2} xs={6}>
                            <div className='service-footer-list'>
                                <div className='service-text mb-2'>SERVICES</div>
                                <div className='service-sub-text'>Sea Freight</div>
                                <div className='service-sub-text'>Road Transport</div>
                                <div className='service-sub-text'>Air Freight</div>
                                <div className='service-sub-text'>Packing & Storage</div>
                                <div className='service-sub-text'>Warehousing</div>
                                <div className='service-sub-text'>Door to Door Delivery</div>


                            </div>

                        </Col>
                        <Col md={2} xs={6}>
                            <div className='service-footer-list'>
                                <div className='service-text mb-2'>QUICK LINK</div>
                                <div className='service-sub-text'>Home</div>
                                <div className='service-sub-text'>Services</div>
                                <div className='service-sub-text'>About</div>
                                <div className='service-sub-text'>Contact</div>
                                <div className='service-sub-text'>Support</div>
                                <div className='service-sub-text'>Careers</div>
                            </div>
                        </Col>

                    </Row>
                </div>
                {trackModal ? 
                 <TrackerModal visible={trackModal} close={() => setTrackModal(false)} />
                :null}
               
            </Container >
   
        </div >
    )
}

export default Footer