import React from "react";
import "./style.css";
import saudiaLogo from "../asset/cargo logo/Group 128.jpg";
import omanLogo from "../asset/cargo logo/Group 129.jpg";
import airIndiaLogo from "../asset/cargo logo/Group 131.jpg";
import jazeeraLogo from "../asset/cargo logo/Group 130.jpg";
import emiratesLogo from "../asset/cargo logo/Group 135.jpg";
import aramexLogo from "../asset/cargo logo/Group 153.jpg";
import royalBruneiLogo from "../asset/cargo logo/Group 134.jpg";
import shippingNepalLogo from "../asset/cargo logo/Group 137.jpg";
import garudaLogo from "../asset/cargo logo/Group 138.jpg";
import srilankanLogo from "../asset/cargo logo/Group 132.jpg";
import expressLogo from "../asset/cargo logo/Group 155.jpg";
import { Container } from "react-bootstrap";
import { Row } from "antd";
import { Col } from "antd";
function Partners(props) {
  return (
    // <Container fluid>
    <div className=''>
      <div
        className={
          props.airport
            ? "cargoPartnersMainHeadgreyBg p-0 cargoPartnersSectionBg"
            : "cargoPartnersMainHead p-0 cargoPartnersSectionBg"
        }
      >
        <div className="cargoPartnersHeading pt-5">Our Cargo Partners</div>
        <div className="cargoPartnersFirstRow mt-3">
          <div className="cargoPartnersFirstRowSub">
            <div
              className={
                props.airport
                  ? "border-0 cargoLogoHeadLargeBox"
                  : "cargoLogoHeadLargeBox"
              }
            >
              <img className="cargoLogoLarge" src={saudiaLogo} />
            </div>
            <div
              className={
                props.airport
                  ? "border-0 cargoLogoHeadLargeBox"
                  : "cargoLogoHeadLargeBox"
              }
            >
              <img className="cargoLogoLarge" src={omanLogo} />
            </div>
            <div
              className={
                props.airport
                  ? "border-0 cargoLogoHeadLargeBox"
                  : "cargoLogoHeadLargeBox"
              }
            >
              <img className="cargoLogoLarge" src={airIndiaLogo} />
            </div>
            <div
              className={
                props.airport
                  ? "border-0 jazeeraCargoLogoHead"
                  : "jazeeraCargoLogoHead"
              }
            >
              <img className="cargoLogoLarge" src={jazeeraLogo} />
            </div>
          </div>
        </div>

        <div className="cargoPartnersSecondRow mb-5 pb-4">
          <div className="cargoPartnersSecondRowsub">
            <div
              className={
                props.airport ? "border-0 emiratesLogoHead" : "emiratesLogoHead"
              }
            >
              <img className="emiratesLogo" src={emiratesLogo} />
            </div>
            <div
              className={
                props.airport ? "border-0 aramexLogoHead" : "aramexLogoHead"
              }
            >
              <img className="aramexLogo" src={aramexLogo} />
            </div>
            <div
              className={
                props.airport
                  ? "border-0 royalBruneiLogoHead"
                  : "royalBruneiLogoHead"
              }
            >
              <img className="royalBruneiLogo" src={royalBruneiLogo} />
            </div>
            <div
              className={
                props.airport
                  ? "border-0 shippingNepalLogoHead"
                  : "shippingNepalLogoHead"
              }
            >
              <img className="shippingNepalLogo" src={shippingNepalLogo} />
            </div>
            <div
              className={
                props.airport ? "border-0 garudaLogoHead" : "garudaLogoHead"
              }
            >
              <img className="garudaLogo" src={garudaLogo} />
            </div>
            <div
              className={
                props.airport
                  ? "border-0 srilankanLogoHead"
                  : "srilankanLogoHead"
              }
            >
              <img className="srilankanLogo" src={srilankanLogo} />
            </div>
            <div
              className={
                props.airport ? "border-0 expressLogoHead" : "expressLogoHead"
              }
            >
              <img className="expressLogo" src={expressLogo} />
            </div>
          </div>
        </div>
      </div>

      {/* {tablet} */}

      <div className="tabCargoPartnersMainHead">
        <div className="tabCargoPartnersHeading mt-5">Our Cargo Partners</div>
        <Row className="mt-4">
          <div className="tabCargoPartnersRow">
            <Col>
              <div className="tabCargoPartnersCol">
                <div className="tabPartnersLogoHead">
                  <img className="tabPartnersLogo" src={saudiaLogo} />
                </div>
              </div>
            </Col>
            <Col>
              <div className="tabCargoPartnersCol">
                <div className="tabPartnersLogoHead">
                  <img className="tabPartnersLogo" src={omanLogo} />
                </div>
              </div>
            </Col>
            <Col>
              <div className="tabCargoPartnersCol">
                <div className="tabPartnersLogoHead">
                  <img className="tabPartnersLogo" src={airIndiaLogo} />
                </div>
              </div>
            </Col>
          </div>
        </Row>
        <Row className="mt-4">
          <div className="tabCargoPartnersRow">
            <Col>
              <div className="tabCargoPartnersCol">
                <div className="tabPartnersLogoHead">
                  <img className="tabPartnersLogo" src={jazeeraLogo} />
                </div>
              </div>
            </Col>
            <Col>
              <div className="tabCargoPartnersCol">
                <div className="tabPartnersLogoHead">
                  <img className="tabPartnersLogo" src={emiratesLogo} />
                </div>
              </div>
            </Col>
            <Col>
              <div className="tabCargoPartnersCol">
                <div className="tabPartnersLogoHead">
                  <img className="tabPartnersLogo" src={aramexLogo} />
                </div>
              </div>
            </Col>
            <Col>
              <div className="tabCargoPartnersCol">
                <div className="tabPartnersLogoHead">
                  <img className="tabPartnersLogo" src={garudaLogo} />
                </div>
              </div>
            </Col>
            <Col>
              <div className="tabCargoPartnersCol">
                <div className="tabPartnersLogoHead">
                  <img className="tabPartnersLogo" src={shippingNepalLogo} />
                </div>
              </div>
            </Col>
          </div>
        </Row>
        <Row className="mt-4">
          <div className="tabCargoPartnersRow">
            <Col>
              <div className="tabCargoPartnersCol">
                <div className="tabPartnersLogoHead">
                  <img className="tabPartnersLogo" src={royalBruneiLogo} />
                </div>
              </div>
            </Col>
            <Col>
              <div className="tabCargoPartnersCol">
                <div className="tabPartnersLogoHead">
                  <img className="tabPartnersLogo" src={srilankanLogo} />
                </div>
              </div>
            </Col>
            <Col>
              <div className="tabCargoPartnersCol">
                <div className="tabPartnersLogoHead">
                  <img className="tabPartnersLogo" src={expressLogo} />
                </div>
              </div>
            </Col>
          </div>
        </Row>
      </div>
      <br />

      {/* {mobile} */}

      <div className="mobCargoPartnersMainHead">
      <div className="mobCargoPartnersHeading mt-5">Our Cargo Partners</div>
        <div className="mobPartnersRow mt-4">
          <span className="mobPartnersCol">
            <img className="mobPartnersLogo" src={jazeeraLogo} />
          </span>
          <span className="mobPartnersSmallCol">
            <img className="mobPartnersLogo" src={emiratesLogo} />
          </span>
          <span className="mobPartnersCol">
            <img className="mobPartnersLogo" src={aramexLogo} />
          </span>
        </div>
        <div className="mobPartnersRow mt-4">
          <span className="mobPartnersCol">
            <img className="mobPartnersLogo" src={saudiaLogo} />
          </span>
          <span className="mobPartnersCol">
            <img className="mobPartnersLogo" src={airIndiaLogo} />
          </span>
        </div>
        <div className="mobPartnersRow mt-4">
          <span className="mobPartnersCol">
            <img className="mobPartnersLogo" src={royalBruneiLogo} />
          </span>
          <span className="mobPartnersSmallCol">
            <img className="mobPartnersLogo" src={srilankanLogo} />
          </span>
          <span className="mobPartnersCol">
            <img className="mobPartnersLogo" src={shippingNepalLogo} />
          </span>
        </div>
        <div className="mobPartnersRow mt-4">
          <span className="mobPartnersCol">
            <img className="mobPartnersLogo" src={shippingNepalLogo} />
          </span>
          <span className="mobPartnersCol">
            <img className="mobPartnersLogo" src={garudaLogo} />
          </span>
        </div>
        <div className="mobPartnersRow mt-4">
          <span className="mobPartnersBigCol">
            <img className="mobPartnersLogo" src={omanLogo} />
          </span>
        </div>
      </div><br/>
    </div>
    // </Container>
  );
}

export default Partners;
