import React from "react";
import "./style.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Container } from "react-bootstrap";
import { IoHelpBuoyOutline } from "react-icons/io5";
import wheelImg from "../asset/image/help-buoy-outline.svg";

function Sectionglad() {
  return (
    <Container fluid className="gladSection workingHrsSectionBg mt-4">
      {/* desktop */}
      {/* test */}
      <Row>
        <div className="mainHeadGlad">
          {/* <Col md={{ span: 2, offset: 3 }}>
            <div>
              <div className="txtHeading">WE'LL BE GLAD TO HELP</div>
              <div className="txt">CONTACT US</div>
            </div>
          </Col> */}
          {/* <Col md={{span:2}}>
            <div>
              <div className="txtHeading">CALL CENTER</div>
              <div className="txt">050 871 7038</div>
              <div className="txt">050 521 6943</div>
            </div>
          </Col> */}
          <Col className="">
            <div >
              <h4 className="workingHrsTextStyle my-0">WORKING HOURS: Mon-Sun 9AM-11PM , FRI 2PM-11PM</h4>
            </div>
          </Col>
          
        </div>
      </Row>
      <br />

      {/* tab */}

      <div className="tabMainHeadGlad ">
        <Row className="tabRow ">
          <Col sm={6}>
            <div className="tabTxtHeading">
              <div className="txtHeading">
                WE'LL BE GLAD <br />
                TO HELP
              </div>
              <img className="wheelImgTab" src={wheelImg} />
            </div>
          </Col>

          <Col sm={6}>
            <div className="txtHeading">CALL CENTER</div>
            <div className="txt">050 871 7038</div>
            <div className="txt">050 521 6943</div>
            <br />
            <div className="txtHeading">WORKING HOURS</div>
            <div className="txt">
              Monday-Thursday
              <br /> 7AM-5PM
            </div>
            <div className="txt">Saturday: 9AM-3PM</div>
            <br />
            <div className="txtHeading">OUR LOICATION</div>
            <div className="txt">SHARAFIYA-JEDDAH</div>
          </Col>
        </Row>
      </div>

      {/* mobile */}

      <div className="mobMainHeadGlad mb-4">
        <br />
        <div className="mobSubHeadGlad">
          {/* <div className="mobIconTxt">
            <div>
              <IoHelpBuoyOutline size={50} color="white"></IoHelpBuoyOutline>
            </div>
            <div className="txtHeading mt-1  ">
              WE'LL BE GLAD <br /> TO HELP
            </div>
          </div> */}
          {/* <div className="txtHeading mt-3">CALL CENTER</div>
          <div className="txt">050 871 7038</div>
          <div className="txt">050 521 6943</div>
          <br /> */}
          <div className="txtHeadingMob">WORKING HOURS</div>
          <div className="txtMob">
            Monday-Sunday
            <br /> 9AM-2PM
          </div>
          <div className="txtMob">Friday: 2PM-11PM</div>
          {/* <br />
          <div className="txtHeading">OUR LOICATION</div>
          <div className="txt">SHARAFIYA-JEDDAH</div> */}
        </div>
      </div>
    </Container>
  );
}

export default Sectionglad;
