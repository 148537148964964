import React, { useState } from "react";
import "./App.css";
import twittericon from "./asset/svgicons/SM twitter.svg";
import facebookicon from "./asset/svgicons/SM facebook.svg";
import instagramicon from "./asset/svgicons/SM instagram.svg";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { AiOutlineArrowRight } from "react-icons/ai";
import androidstore from "./asset/image/android-icon.png";
import applestore from "./asset/image/apple-icon.png";
import { Link } from "react-router-dom";

function Header() {
  const [hover, setHover] = useState();
  const [istoggleOpen, setIsToggleOpen] = useState(true);

  const onFocus = () => {
    setHover(<AiOutlineArrowRight size={20} />);
  };

  return (
    <Navbar
      className={
        istoggleOpen
          ? "nav-bar-set pt-4 fixed-top"
          : "nav-bar-setted pt-4 fixed-top"
      }
      expand="md"
    >
      <>
        <div className="social-icon-nav">
          <a href="#">
            <img className="twitt-icon me-4 ms-5" src={twittericon}></img>
          </a>
          <a href="https://www.facebook.com/saharicargo?mibextid=ZbWKwL">
            <img className="fb-icon pe-4" src={facebookicon}></img>
          </a>
          <a href="https://instagram.com/saharicargo?igshid=MzRlODBiNWFlZA==">
            <img className="insta-icon " src={instagramicon}></img>
          </a>
        </div>
        <Navbar.Toggle
          className="hh"
          aria-controls="basic-navbar-nav"
          onClick={() => setIsToggleOpen(!istoggleOpen)}
        />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="nav-option-list ms-auto ">
            <Nav.Link>
              <Link to={"/"} className="pathNavHeader">
                <div className="nav-option me-2" href="#">
                  HOME
                </div>
              </Link>
            </Nav.Link>
            <Nav.Link>
              <Link to={"/AboutUs"} className="pathNavHeader">
                <div className="nav-option me-2" href="#">
                  ABOUT-US
                </div>
              </Link>
            </Nav.Link>
            <Link to={"/trackshipment"} className="pathNavHeader">
              <button className="nav-tracking-bttn d-sm-none d-block">
                TRACKING
              </button>
            </Link>
            <Nav.Link>
              <Link to={"/Services"} className="pathNavHeader">
                <div className="nav-option me-2" href="#">
                  OUR-SERVICES
                </div>
              </Link>
            </Nav.Link>
            <Nav.Link className="d-none d-sm-block">
              <Link to={"/trackshipment"} className="pathNavHeader">
                <div className="nav-option me-2 d-none d-sm-block" href="#">
                  TRACKING
                </div>
              </Link>
            </Nav.Link>
            <Nav.Link>
              <Link to={"/Contact"} className="pathNavHeader">
                <div className="nav-option me-2" href="#">
                  CONTACT-US
                </div>
              </Link>
            </Nav.Link>
            <div className="request-button  pe-2">
              <Link to={"/requestQuote"} className="pathNavHeader">
                <button
                  className={hover ? "request-quote-hover" : "request-quote"}
                  onMouseEnter={onFocus}
                  onMouseLeave={() => setHover("")}
                >
                  REQUEST QUOTE {hover}
                </button>
              </Link>
              <div className="rate-calculator-mob mt-3 d-sm-none d-block">
                <a href="#">
                  <button className="calculator-mob">RATE CALCULATOR</button>
                </a>
              </div>
              <div className="download-App d-sm-none d-block">
                <span className="download-App-text ">DOWNLOAD APP NOW</span>
                <div className="app-Store d-sm-none d=-none mt-3">
                  <a href="#">
                    <img className="apple-App" src={applestore}></img>
                  </a>
                  <a href="#">
                    <img className="android-App" src={androidstore}></img>
                  </a>
                </div>
                <div className="social-icon-nav-mob me-5 mt-4">
                  <a href="#">
                    <img
                      className="twitt-icon-nav me-4 ms-5"
                      src={twittericon}
                    ></img>
                  </a>
                  <a href="https://www.facebook.com/saharicargo?mibextid=ZbWKwL">
                    <img className="fb-icon-nav pe-4" src={facebookicon}></img>
                  </a>
                  <a href="https://instagram.com/saharicargo?igshid=MzRlODBiNWFlZA==">
                    <img className="insta-icon-nav " src={instagramicon}></img>
                  </a>
                </div>
              </div>
            </div>
          </Nav>
        </Navbar.Collapse>
      </>
    </Navbar>
  );
}
export default Header;
