import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import CourierService from '../src/courierService';
import AirportToAirport from '../src/airport';
import DoorToDoor from './doorToDoor';
import PackingAndShipping from './packing&Shipping';
import About from './AboutUs';
import Service from './benefits';
import Branches from './branches';
import 'antd/dist/reset.css';

import Freequotes from '../src/freequotes';
import TrackerModal from './TrackModal/TrackerModal';
import Section1 from './section1';

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },

  {
    path: "/courierService",
    element: < CourierService />,
  },

  {
    path: "/airport",
    element: <AirportToAirport />,
  },
  {
    path: "/doorToDoor",
    element: <DoorToDoor />,
  },
  {
    path: "/packingAndShipping",
    element: <PackingAndShipping />,
  },
  {
    path: "/AboutUs",
    element: <About />,
  },
  {
    path: "/Services",
    element: <Service />,
  },
  {
    path: "/Contact",
    element: <Branches />
  },
  {
    path: "/requestQuote",
    element: <Freequotes />,
  },
  {
    path: "/trackshipment",
    element: <Section1 />
  }


]);
ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <RouterProvider router={router} />
    {/* <App /> */}
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
