import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./style.css";
import Header from "../header";
import Footer from "../footer";
import { useLocation } from "react-router-dom";
import Shippingdestination from "../shippingdestination";
import Partners from "../cargoPartners";
import AboutImg from '../asset/image/deliveryman2.jpeg';
import { AbcOutlined } from "@mui/icons-material";

function About() {
  const location = useLocation();
  return (
    <Container fluid className="about-Section-Bg mb-5 ">
      <Row className={location.pathname === "/AboutUs" ? "aboutUsRow" : null}>
        <Col md={6} xs={12} className="">
          <img className="about-image" src={AboutImg}></img>
        </Col>
        <Col md={6} sm={12} xs={12} className="aboutUsTxt py-md-5">
          <div className="aboutUsHead">
            <div className="about-US-Text p-3 ">
              <div className="who-We-Are">WHO WE ARE</div>
              <div className="about-Text mt-2">About Us</div>
              <div className="about-Main-Text mt-2">
                It is a pleasure to announce that your things would reach the
                safe destination through "SAHARI CARGO". we are service minded
                organisation which specialised in Door-to-Door,
                Airport-to-Airport, Shipping etc.
              </div>
            </div>
            <div className="about-Contact-Button ms-3 mt-2">
              {/* <button className="Contact-Bttn">Contact US</button> */}
            </div>
            <div className="about-Text-Sub p-3 mt-2">
              <p className="text-about-us">
                We provide a comprehensive range of transportation service from
                Air, Sea and road. Our mission is to exceed customer
                expectations in the transfer of their goods around the world. We
                deliver value to our customers by providing the most reliable
                efficient solution in the cargo services.
              </p>

              <p className="text-about-us">
                Our experience in this industry has allowed us to constantly
                provide reliable Air and Sea services from Saudi Arabia to all
                over the world. Our compressive service ensures that your cargo
                is handled professionally and securely on time. In Saudi Arabia
                we have over 10+ years experience wondering us an independent
                logistics specialist.
              </p>
            </div>
          </div>
        </Col>
        <Header />
      </Row>

      {location.pathname === "/AboutUs" ? <Shippingdestination /> : null}
      {location.pathname === "/AboutUs" ? <Partners /> : null}
      {location.pathname === "/AboutUs" ? (
        <div className="footer">
          <Footer />
        </div>
      ) : null}
    </Container>
  );
}

export default About;
