import React, { useEffect } from "react";
import "./style.css";
import { Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Header from "../header";
import Partners from "../cargoPartners";
import Branches from "../branches";
import Footer from "../footer";

function AirportToAirport() {
  useEffect(()=>{
    window.scrollTo(0,0)
  },[])

  return (
    <Container fluid className="p-0">
      <Header/>
      <div>
        <div className="AirportImg"></div>
        <Row className="p-0 g-0">
          <Col md={6}>
            <div className="domesticColHead">
              <div className="domesticColHeadSub">
                <div className="domesticHeading">AIRPORT TO AIRPORT</div>
                <div className="domesticTxt mt-5">
                  When time is of the essence and you require the utmost in
                  speed and reliability for your cargo, air freight is the
                  premier shipping solution. While it may come with a higher
                  cost, the benefits of timely delivery and the potential to
                  enhance the reputation of your brand make it a worthwhile
                  investment. At SAHARICargo, we offer unparalleled air freight
                  services that are designed to meet and exceed the expectations
                  of our esteemed clients.<br/><br/> Our team of experienced professionals
                  is committed to delivering top-notch shipping services that
                  prioritize the timely and safe transport of your cargo.
                  Whether you are in need of a rapid delivery for a small
                  package or a large shipment, we have the resources and
                  expertise to ensure that your cargo arrives at its destination
                  promptly and without incident.<br/><br/> When you choose SAHARI Cargo
                  for your air freight needs, you can trust that your cargo is
                  in capable hands. We are dedicated to providing the highest
                  level of service and are committed to exceeding your
                  expectations with every shipment. So if you require the speed
                  and reliability of air freight for your shipping needs, don't
                  hesitate to turn to us. We will make sure that your products
                  arrive at their destination on time, every time.
                </div>
              </div>
            </div>
          </Col>
          <Col md={6}>
          <div className="domesticFormColHead">
            <div className="domesticFormColHeadSub">
              <div className="domesticFormHeading">
                 Online Contact Form
              </div>
              <div className="courierForm mt-5">
                <div className="nameEmail mt-4">
                  <div className="name">
                    <label>Name</label>
                    <input className="input" type={"text"}></input>
                  </div>
                  <div className="email">
                    <label>e-mail Id</label>
                    <input className="input" type={"email"}></input>
                  </div>
                </div>
                <div className="telephoneSubject mt-2">
                  <div className="telephone">
                    <label>Telephone</label>
                    <input className="input" type={"number"}></input>
                  </div>
                  <div className="subject">
                    <label>Subject</label>
                    <input className="input" type={"text"}></input>
                  </div>
                </div>
                <div className="textAreaHead mt-2">
                  <label className="moreDetails" form="message">
                    More details
                  </label>
                  <textarea
                    id="w3review"
                    name="w3review"
                    rows="4"
                    cols="50"
                    class="message"
                  ></textarea>
                </div>
                {/* verificationCode */}
                <div>
                  {/* <div className='verificationCodeTxt mt-2'>Verification Code</div>
                  <div className='verificationCode'>43738</div> */}
                  <div className="inputAndBtn">
                    {/* <input className='verifcationInput'></input> */}
                    <div className="submitBtnDomesticFormHead">
                      <button className="submitBtnDomesticForm">SUBMIT</button>
                    </div>
                  </div>
                </div>
                {/* verificationCode */}
              </div>
            </div>
          </div>
          </Col>
        </Row>
      </div><br/>
      <Partners airport={true} />
      <Branches white={true}/>
      <Footer/>
    </Container>
  );
}

export default AirportToAirport;
